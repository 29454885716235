import React, { useEffect, useRef, useState,ReactNode } from 'react';
import styled from "styled-components";

interface StickyProps {
    children: ReactNode;
}

export const StickyInner = styled.div`
margin:0;

  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
    
  }

`;

export const StickyWrapper = styled.div`

  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
    padding:0
  }
 
    &.isActive{
        ${StickyInner}{
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            width:16.666666666666664%;
            background:${({ theme: { colors } }) => colors.greys.xxlight};
            @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
              position: relative;
              top: 0;
              left: 0;
              z-index: 1;
              width:100%;
            }
        }
    }

`;

export const Sticky = (props: StickyProps) => {
    const [isSticky, setSticky] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const handleScroll = () => {
      if (ref.current) {
        setSticky(ref.current.getBoundingClientRect().top <= 0);
      }
    };
  
    useEffect(() => {
      window.addEventListener(`scroll`, handleScroll);
      return () => {
        window.removeEventListener(`scroll`, () => handleScroll);
      };
    }, []);
  
    return (
        <StickyWrapper className={`sticky-wrapper${isSticky ? ` isActive` : ``}`} ref={ref}>
          <StickyInner>
              {props.children}
          </StickyInner>
        </StickyWrapper>
    );
  };
