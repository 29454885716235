import React, { useState, useEffect } from "react";
import Logo from "../shared/Logo";
import { Link } from 'react-router-dom';
import { NavHashLink as LinkHash } from 'react-router-hash-link';
import styled from "styled-components";
import { Subdomain } from '../../common/authConfig';

//#region Styled Components
const HeaderMainContainer = styled.header`
    background: ${({ theme: { colors } }) => colors.greys.medium};
    color: ${({ theme: { colors } }) => colors.greys.dark};
    border-bottom: 8px solid ${({ theme: { colors } }) => colors.primary.main};
    @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
        display:block;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.print} { 
        display:none;
    }
`;
const Navbar = styled.div`   
    width: 100%;
    max-width: 1900px;
    padding: .5rem 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;height: 80px;
 .5rem 1rem
    @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
        width: auto;
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.tablet} { 
        width: 100%;
    }

    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
        
        justify-content: center;
        flex-direction: column;
        align-content: space-between;
        &.NavVisible{
            flex-direction: row;justify-content: space-between; height: auto;
            ul{
                width: 100%;
            }

        }
    }

`;

const NavigationList = styled.ul`
    margin:13px 0;text-align: center;padding-left: 15px;
    flex-grow: 3;
    align-content: flex-start;
    display: flex;

    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
        padding: 0;
        justify-content: center;
        flex-direction: column;
        & li{
            margin-right:0;margin-left:0;width: 100%;
        }
        & a{
            width: 100%;padding: 15px 0;cursor:pointer;
        }
       
    }



`;

const NavigationItem = styled.li`
    display: inline;margin-right:10px;
    & a{
        display: inline-block;
        padding: 15px; color:${({ theme: { colors } }) => colors.greys.xxmedium}; 
        font-family:${({ theme: { fonts } }) => fonts.alt}, 'sans-serif'; 
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.5px;
        &:hover, &.active{
            background: ${({ theme: { colors } }) => colors.primary.main};color:${({ theme: { colors } }) => colors.white}; 
        }
    }
`;

const NavigationLink = styled(Link)`
    display: inline-block;
    padding:15px; color:${({ theme: { colors } }) => colors.greys.xxmedium}; 
    font-family:${({ theme: { fonts } }) => fonts.alt}, 'sans-serif'; 
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    &:hover{
        background: ${({ theme: { colors } }) => colors.primary.main};color:${({ theme: { colors } }) => colors.white}; 
    }
`;
const NavigationLinkHash = styled(LinkHash)`
    display: inline-block;
    padding: 15px; color:${({ theme: { colors } }) => colors.greys.xxmedium}; 
    font-family:${({ theme: { fonts } }) => fonts.alt}, 'sans-serif'; 
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
    &:hover, &.active{
        background: ${({ theme: { colors } }) => colors.primary.main};color:${({ theme: { colors } }) => colors.white}; 
    }
`;
const NavigationLinkHref = styled.a`
    display: inline-block;cursor:pointer;
    padding: 15px; color:${({ theme: { colors } }) => colors.greys.xxmedium}; 
    font-family:${({ theme: { fonts } }) => fonts.alt}, 'sans-serif'; 
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;cursor:pointer;
    &:hover, &.active{
        background: ${({ theme: { colors } }) => colors.primary.main};color:${({ theme: { colors } }) => colors.white}; 
    }
`;
const PrintHeader = styled.img`
    width:100%;display:none;
    @media ${({ theme: { deviceSizes } }) => deviceSizes.print} { 
        display:block;
    }
`;
const HamburgerIcon = styled.a`
    position: relative; display:none;cursor:pointer;
    padding: .25rem .75rem;
    width: 25px;
    height: 25px;
    border: 1px solid #a6a6a6;
    margin-right: 20px;
    &:before {
        content: "";
        position: absolute;
        left: 13px;
        top:.5em;
        width: 1.5em;
        height: 0.15em;
        background: ${({ theme: { colors } }) => colors.greys.dark};
        box-shadow: 0 0.40em 0 0 ${({ theme: { colors } }) => colors.greys.dark}, 0 0.75em 0 0 ${({ theme: { colors } }) => colors.greys.dark};
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
        display:block;
    }
`;
//#endregion

export default function NavMenu() {
    const [isNavVisible, setNavVisibility] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
  
    useEffect(() => {
      const mediaQuery = window.matchMedia(`(max-width: 768px)`);
      mediaQuery.addListener(handleMediaQueryChange);
      handleMediaQueryChange(mediaQuery);
  
      return () => {
        mediaQuery.removeListener(handleMediaQueryChange);
      };
    }, []);
  
    const handleMediaQueryChange = (mediaQuery:any) => {
      if (mediaQuery.matches) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
  
    const toggleNav = () => {
      setNavVisibility(!isNavVisible);
    };

    return (
        <>
            <HeaderMainContainer>
                <Navbar className={isNavVisible ? `NavVisible` : ``}>
                    <Logo/>
                    <HamburgerIcon onClick={toggleNav}></HamburgerIcon>
                    {(!isSmallScreen || isNavVisible) && (
                        <NavigationList>
                            <NavigationItem>
                                <NavigationLinkHref href={`https://${Subdomain}.alas.com/`}>Home</NavigationLinkHref>
                            </NavigationItem>
                            <NavigationItem>
                                <NavigationLink onClick={toggleNav} to="/">The Alas Edge</NavigationLink>
                            </NavigationItem>
                            <NavigationItem>
                                <NavigationLinkHash onClick={toggleNav}  to="/#MDVToAlasEdge" scroll={(el: any) => el.scrollIntoView({ behavior: `smooth`, block: `start` })}>Data Ethics</NavigationLinkHash>
                            </NavigationItem>
                            <NavigationItem style={{marginLeft:`auto`, marginRight:`30px`}}>
                                <NavigationLinkHref className='active' href={`https://${Subdomain}.alas.com/secure/member-home.aspx`}>Members Home</NavigationLinkHref>
                            </NavigationItem>                             
                        </NavigationList>
                    )}
                    
                </Navbar>
            </HeaderMainContainer>
            <PrintHeader src={require(`../../assets/print-header-2.png`)} alt='header' />
        </>
    ); 
}
