import { SubTopic } from '../../models/SubTopic';
import { Topic } from '../../models/Topic';

export function convertFileNameToKey(str: string) {
    return str.replace(/\.[^/.]+$/, ``);    
}

export function convertFileName(str: string) {
    let fileNameWithoutExtension = str.replace(/\.[^/.]+$/, ``);    
    return fileNameWithoutExtension.replace(/-/g, ` `);
}

export function getTitleText(t: Topic | SubTopic) {
    return t.metadata ? t.metadata.title : convertFileName(t.blob.name);
}

export function getTitleUrl(topic: Topic | undefined) {
    if(topic && topic.blob){
        return convertFileNameToKey(topic.blob.name);
    } else {
        return ``;
    }

}
 