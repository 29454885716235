import * as moment from "moment";
import * as AzureStorageBlob from "@azure/storage-blob";

export interface TopicMetaData {
    lastModified: moment.Moment;
    title: string;
}

export class Topic {
    public static hasSubTopics = (topic: Topic): boolean => {
        if (topic.subTopicKeys.length && (topic.subTopicKeys.length > 1 || (topic.subTopicKeys[0] !== ``))) {
            return true;
        }
        return false;
    }

    constructor(
        public blob: AzureStorageBlob.BlobItem,        
        public metadata: TopicMetaData | undefined = undefined,
        public htmlContent: any = undefined,
        public subTopicKeys: Array<string> = [],
        public tagKeys: Array<string> = [],
        public thumbnail: string = ``,
        public priority: number = 0,
        public visible: boolean = false,
        public featured: boolean = false,
        public summary: string = ``,
        public updatedTimestamp: string = ``,
        public hasAccess: boolean = true
    ) {}
}