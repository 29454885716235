import React, { useState,ReactNode, useRef, useEffect  } from 'react';
import styled from "styled-components";
 
import { ReadMoreButton } from "./Buttons";

interface FlexLabelProps {
    children: ReactNode;
    maxWidth?: string;
}

interface FadeProps {
  children: ReactNode;
  subTopicLength:number;
}

interface FlexListProps {
  maxWidth?:string;
  noMargin?:string;
  small?:boolean;
}

interface FlexListItemProps {
  mobileFullWidth?: boolean;
  maxWidth?:string;
}

interface FlexLabelProps{
  isHeading?:boolean;
  maxWidth?:string;
}

export const FlexList = styled.ul<FlexListProps>`
    margin: 0 auto;
 
  font-weight: normal;
  display: flex;
  flex-direction: row;flex-wrap: wrap;
  justify-content: start;
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : `none`};
  padding:${({ noMargin, theme: { padding } }) => noMargin ? `0` : `0 15px` };

  ${({ small }) => small ? `padding: 0; & li{margin-bottom: 10px;border:none;}`: `` };

  

`;

export const FlexListItem = styled.li<FlexListItemProps>`
 
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : `none`};
  float:right; border-bottom:1px solid ${({ theme: { colors } }) => colors.greys.xlight};margin-bottom: 20px;
  min-width: 30%;flex-basis: 100%;

  ${({ mobileFullWidth }) => mobileFullWidth && 
      `flex-basis: 30%;margin-right:10px;`
    };
 
    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
      ${({ mobileFullWidth }) => mobileFullWidth && 
        `flex-basis: 100%;margin:0px;`
      };
    }
  


`;

const FlexLabelStyled = styled.div<FlexLabelProps>`
  font-size: ${({ isHeading, theme: { fontSizes } }) => isHeading ? fontSizes.large : fontSizes.small};
  width: 100%;
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : `none`};
  float:right;
  
`;

export const Fade = styled.div`
background: linear-gradient(to bottom, rgba(100, 100, 100, 0) 0%, #ffffff 90%);
height: 100px;
margin-top: -100px;
position: relative;
`;

export const StickyInner = styled.div`
margin: ${({ theme: { padding } }) => padding.large} 0;
`;

export const FadeWrapper = styled.div`
  margin: 15px 0 0;
  &.isActive{
      ${Fade}{
          display:none;
      }
      & > div {height:auto !important;}
  }
`;

export const FadeToggler = (props: FadeProps) => {
  const [showResults, setShowResults] = useState(false);
  const onClick = () => setShowResults(!showResults);

  const parentRef  = useRef<HTMLDivElement>(null);
  const fadeRef  = useRef<HTMLDivElement>(null);

  useEffect ( () => {
      if(parentRef.current && fadeRef.current){
        //let parentHeight = parentRef.current.offsetHeight;
        if(props.subTopicLength > 4){
          parentRef.current.style.height = `230px`;
          fadeRef.current.style.display = `block`;
        }else{
          parentRef.current.style.height = `auto`;
          fadeRef.current.style.display = `none`;
        }
      }
  }, [parentRef,props]);

  return (
      <FadeWrapper className={showResults ? ` isActive` : ``} >
        <div ref = { parentRef } style={{ overflow: `hidden`}}>
          {props.children}
        </div>
        <div ref = { fadeRef }>
          <Fade></Fade>
          <ReadMoreButton to={`#`} onClick={onClick} style={{textAlign: `center`, width:`100%`, display:`block`}}>
            {showResults ? `Less` : `Show All`}
          </ReadMoreButton>
        </div> 
      </FadeWrapper>
  );
};

export const TextOverflow = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; 
  font-size: ${({ theme: { fontSizes } }) => fontSizes.titleSmall}; 
  text-align: initial;
  margin-bottom: .5rem;
  line-height: 1.2;

`;
 
const FlexLabel = ({ children, ...props }: FlexLabelProps) => (
    <FlexLabelStyled maxWidth={props.maxWidth}>
        {children}
    </FlexLabelStyled>
);
export default FlexLabel;