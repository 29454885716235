import { Route } from 'react-router-dom';
import * as React from 'react';
import App from './components/app/App';
import Theme from './components/shared/Theme';
import { Layout } from './components/layout/Layout';

const AppRouter = () => (
    <Theme>
        <App>
            <Route path="/" component={Layout}/>
        </App> 
    </Theme>
);

export default AppRouter;