import { Reducer } from "redux";
import { UPDATE_LOADEDSEARCHDATA, UPSERT_SEARCHOPTIONS } from "../actions/actionTypes";
import { SearchDataType } from "../../models/enums/SearchDataType";
import { SearchOptionGroup } from "../../models/SearchOptions";

export const searchDataReducer: Reducer<Array<SearchDataType>> = (state = [], action) => {
    switch (action.type) {
        case UPDATE_LOADEDSEARCHDATA:
            let newData: Array<SearchDataType> = action.payload;
            let newState: Array<SearchDataType> = [...state];
            newData.forEach(newDataType => {         
                if (!newState.some(dataType => dataType === newDataType)) {
                    newState.push(newDataType);
                }
            }); 
            return newState;
        default:
            return state;
    }
};

export const searchOptionsReducer: Reducer<Array<SearchOptionGroup>> = (state = [], action) => {
    switch (action.type) {
        case UPSERT_SEARCHOPTIONS:
            let newData: Array<SearchOptionGroup> = action.payload;
            let newState: Array<SearchOptionGroup> = [...state];
            newData.forEach(newDataType => {         
                if (!newState.some(dataType => dataType === newDataType)) {
                    newState.push(newDataType);
                }
            }); 
            return newState;
        default:
            return state;
    }
};