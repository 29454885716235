import * as React from 'react';
import styled from "styled-components";

interface IncomingProps {
    maxWidth?: string;
    textColor?: string;
}
 
const FlexLabelStyled = styled.a`
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  width: 100px; margin:0 0 0 30px;
  
`;

const Logo = ({...props }: IncomingProps) => {
    // const letterColor: string = props.textColor ? props.textColor : "#fff";
    return (
      <FlexLabelStyled>
          <img style={{ width: `100%` }} src={require(`../../assets/Logo_100_gray.png`)} alt='logo' />
      </FlexLabelStyled>
    );
};
 
export default Logo;

const SubLogoStyled = styled.img`
 width:242px;
  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
    width: 170px;
  }
 
`;

export const SubLogo = ({...props }: IncomingProps) => {
  return (
      <SubLogoStyled src={require(`../../assets/edge-logo.png`)} alt='logo' />
  );
};