import moment from 'moment';
import { SearchDataType } from "../../models/enums/SearchDataType";
import { SearchType } from "../../models/enums/SearchType";
import { SearchResult } from "../../models/SearchResult";
import { SearchOption, SearchOptionGroup } from "../../models/SearchOptions";
import { SubTopic } from "../../models/SubTopic";
import { Topic } from "../../models/Topic";
import { convertFileNameToKey, getTitleText, convertFileName } from "./stringHelpers";

export const requiredDataForTagSearch: Array<SearchDataType> = [SearchDataType.Topics, SearchDataType.TopicMetaData, SearchDataType.TopicTags];
export const requiredDataForPhraseSearch: Array<SearchDataType> = [SearchDataType.Topics, SearchDataType.TopicMetaData, SearchDataType.TopicTags, SearchDataType.TopicSubTopicKeys, SearchDataType.SubTopics, SearchDataType.SubTopicMetaData];

export const standardDateFormat: string = `MM[/]DD[/]YYYY`;
export const standardDateTimeFormat = `MMMM Do YYYY, h:mm:ss a`;

export function getSearchType(phrase: string = ``, tag: string = ``): SearchType {
    return (phrase && tag) ? SearchType.Hybrid : tag ? SearchType.Tag : SearchType.Phrase;
}

export function getSearchResults(loadedSearchData: Array<SearchDataType>, topics: Array<Topic>, subTopics: Array<SubTopic>, phrase: string = ``, tag: string = ``): Array<SearchResult> {
    let results: Array<SearchResult> = [];
    let requiredData = requiredDataForTagSearch;

    if (requiredData.every(rd => loadedSearchData.includes(rd))) {
        let searchType: SearchType = getSearchType(phrase?.toString(), tag?.toString());
        switch(searchType) {
            case SearchType.Hybrid:
                // Do a normal phrase search, but only return results that also include the specified tag
                results = getSearchPhraseResults(loadedSearchData, topics, subTopics, phrase, tag);
                break;
            case SearchType.Tag:
                // Find topics with a specific tag
                results = getSearchTagResults(loadedSearchData, topics, tag);
                break;
            case SearchType.Phrase:
            default:
                // Find any topic that contains the phrase provided in it's title, one of it's tags, or one of it's sub-topic titles
                results = getSearchPhraseResults(loadedSearchData, topics, subTopics, phrase);
        }
    }

    return results;
}

export function getSearchTagResults(loadedSearchData: Array<SearchDataType>, topics: Array<Topic>, tag: string): Array<SearchResult> {
    let results: Array<SearchResult> = [];
    let requiredData = requiredDataForTagSearch;

    if (tag && requiredData.every(rd => loadedSearchData.includes(rd))) {
        let searchPhrase = tag.toUpperCase();
        let searchRegex = new RegExp(searchPhrase, `g`);
        
        // Determine if the topic's tags include the provided search phrase
        topics.forEach(t => {
            let totalTagMatches = t.tagKeys.reduce((totalMatches, tagKey) => {
                let currentTagMatches = (tagKey.toUpperCase().match(searchRegex) || []).length; // # of times the phrase was found in the tag
                return totalMatches += currentTagMatches;
            }, 0);            
            let hasRelevantTag = totalTagMatches > 0;

            // if a matching tag was found, add a search result
            if (hasRelevantTag) {
                results.push(SearchResult.fromTopic(t));
            }
        });
    }

    return sortSearchResults(results);
}

export function getSearchPhraseResults(loadedSearchData: Array<SearchDataType>, topics: Array<Topic>, subTopics: Array<SubTopic>, phrase: string, requiredTag?: string): Array<SearchResult> {
    let results: Array<SearchResult> = [];
    let requiredData = requiredDataForPhraseSearch;

    if (phrase && requiredData.every(rd => loadedSearchData.includes(rd))) {
        let searchPhrase = phrase.toUpperCase();
        let searchRegex = new RegExp(searchPhrase, `g`);

        topics.filter(t => t.hasAccess).forEach(t => {
            let title = getTitleText(t);

            // Determine if the topic's title includes the provided search phrase
            let titleMatches = (title.toUpperCase().match(searchRegex) || []).length; // # of times the phrase was found in the title
            let hasRelevantTitle = titleMatches > 0;

            // Determine if the topic's tags include the provided search phrase
            let totalTagMatches = t.tagKeys.reduce((totalMatches, tagKey) => {
                let currentTagMatches = (tagKey.toUpperCase().match(searchRegex) || []).length; // # of times the phrase was found in the tag
                return totalMatches += currentTagMatches;
            }, 0);            
            let hasRelevantTag = totalTagMatches > 0;

            // Add a search result to the list of results if appropriate
            if (hasRelevantTitle || hasRelevantTag) {
                if (requiredTag) {
                    if (t.tagKeys.some(tk => tk.toUpperCase() === requiredTag.toUpperCase())) {                            
                        results.push(SearchResult.fromTopic(t));
                    }
                } else {
                    results.push(SearchResult.fromTopic(t));
                }
            }

            // Determine if any sub-topic titles include the provided search phrase
            t.subTopicKeys.forEach(stk => {                        
                let subTopic = subTopics.find(st => convertFileNameToKey(st.blob.name) === stk);
                if (subTopic) {
                    let subTopicTitle = getTitleText(subTopic);
                    let subTopicTitleMatches = (subTopicTitle.toUpperCase().match(searchRegex) || []).length; // # of times the phrase was found in the title
                    if (subTopicTitleMatches) {
                        results.push(SearchResult.fromTopic(t, subTopic));
                    }
                }
            });
        });
    }
    return sortSearchResults(results);
}

export function getSearchOptions(topics: Array<Topic>, subTopics: Array<SubTopic>, loadedSearchData: Array<SearchDataType>): Array<SearchOptionGroup> {
    let topicOptions: Array<SearchOption> = [];
    let subTopicOptions: Array<SearchOption> = [];
    let tagOptions: Array<SearchOption> = [];
    
    if ( requiredDataForPhraseSearch.every(rd => loadedSearchData.includes(rd))) {
        // Add Topic title options and tags
        topics.filter(t=>t.hasAccess).forEach(t => {
            let topicTitleOption = { 
                value: convertFileNameToKey(t.blob.name).toLowerCase(), 
                label: getTitleText(t)  
            };
            topicOptions.push(topicTitleOption);
            
            t.tagKeys.forEach((tagKey) => {
                
                let i = tagOptions.findIndex(x => x.value === convertFileNameToKey(tagKey).toLowerCase());
                if(i === -1){
                    // Only add if tag is unique 
                    let tagOption = { 
                        value: convertFileNameToKey(tagKey).toLowerCase(), 
                        label: convertFileName(tagKey) 
                    };
                    return tagOptions.push(tagOption);
                }

            });

            t.subTopicKeys.forEach((subTopicKey) => {
                //console.log(subTopicKey);
                let st = subTopics.find(s => convertFileNameToKey(s.blob.name).toLowerCase() == subTopicKey.toLowerCase());
                if (st !== undefined) {
                    let subTopicTitleOption = {
                        value: convertFileNameToKey(st.blob.name).toLowerCase(),
                        label: getTitleText(st)
                    };
                    subTopicOptions.push(subTopicTitleOption);
                }
            });
        
        });
    }

    return [
        {
            label: `Topics`,
            options: topicOptions
        },
        {
            label: `Sub Topics`,
            options: subTopicOptions
        },
        {
            label: `Tags`,
            options: tagOptions
        },
    ];
}

export function sortSearchResults(searchResults: Array<SearchResult>): Array<SearchResult> {
    return searchResults.sort((prevResult, nextResult) => {        
        if (nextResult.relevance - prevResult.relevance !== 0) {
            // If one result is more relevant than the other, show the more relevant result (higher relevance value) first
            return nextResult.relevance - prevResult.relevance;
        } else if (prevResult.lastModified.diff(nextResult.lastModified, `s`)) {
            // If both results are equally relevant, give the most recently modified result priority
            return prevResult.lastModified.isAfter(nextResult.lastModified) ? -1 : 1;
        } else {
            // If all other factors are equal, sort results alphabetically according to the search result title
            return prevResult.title.toUpperCase().localeCompare(nextResult.title.toUpperCase());
        }
    });
}

export function getLastModifiedDate(loadedSearchData: Array<SearchDataType>, topics: Array<Topic>, subTopics: Array<SubTopic> = []): string {
    let requiredData = [SearchDataType.Topics, SearchDataType.TopicMetaData];
    if (subTopics.length) {
        requiredData = [...requiredData, ...[SearchDataType.SubTopics, SearchDataType.SubTopicMetaData]];
    }
    let necessaryDataLoaded = requiredData.every(rd => loadedSearchData.includes(rd));
    let placeholder = `MM/DD/YYYY`;

    if (necessaryDataLoaded && topics.length) {
        let mostRecentlyModifiedTopic: Topic= topics.reduce((prevTopic, nextTopic) => {
            let prevTopicModified = prevTopic.metadata ? prevTopic.metadata.lastModified : moment(); // this should ALWAYS return "prevTopic.metadata.lastModified" (but Typescript doesn't know that)
            let nextTopicModified = nextTopic.metadata ? nextTopic.metadata.lastModified : moment(); // this should ALWAYS return "nextTopic.metadata.lastModified" (but Typescript doesn't know that)
            return prevTopicModified.isAfter(nextTopicModified) ? prevTopic : nextTopic;
        });

        let mostRecentlyModifiedSubTopic: SubTopic | undefined = undefined;
        if (subTopics.length) {
            mostRecentlyModifiedSubTopic = subTopics.reduce((prevTopic, nextTopic) => {
                let prevTopicModified = prevTopic.metadata ? prevTopic.metadata.lastModified : moment(); // this should ALWAYS return "prevTopic.metadata.lastModified" (but Typescript doesn't know that)
                let nextTopicModified = nextTopic.metadata ? nextTopic.metadata.lastModified : moment(); // this should ALWAYS return "nextTopic.metadata.lastModified" (but Typescript doesn't know that)
                return prevTopicModified.isAfter(nextTopicModified) ? prevTopic : nextTopic;
            });
        }

        let lastModifiedDate: moment.Moment | undefined = undefined;
        
        if (mostRecentlyModifiedTopic && mostRecentlyModifiedTopic.metadata && mostRecentlyModifiedSubTopic && mostRecentlyModifiedSubTopic.metadata) {
            lastModifiedDate = mostRecentlyModifiedTopic.metadata.lastModified.isAfter(mostRecentlyModifiedSubTopic.metadata.lastModified) ?
                mostRecentlyModifiedTopic.metadata.lastModified : mostRecentlyModifiedSubTopic.metadata.lastModified;
        } else if (mostRecentlyModifiedTopic && mostRecentlyModifiedTopic.metadata) {
            lastModifiedDate = mostRecentlyModifiedTopic.metadata.lastModified;
        } else if (mostRecentlyModifiedSubTopic && mostRecentlyModifiedSubTopic.metadata) {
            lastModifiedDate = mostRecentlyModifiedSubTopic.metadata.lastModified;
        } 

        return lastModifiedDate ? lastModifiedDate?.format(standardDateFormat) : placeholder; // this should ALWAYS return a lastModifiedDate (but Typescript doesn't know that)
    }
    return placeholder;
}
