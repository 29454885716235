import { ParsedQuery } from "query-string";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { State } from "../../../store/rootReducer";
import * as queryString from 'query-string';
import {Row,Col, Padding} from "../../../common/helpers/styling/ResponsiveGrid";
import {H5, H1} from "../../../common/helpers/styling/Typography";
import { ReadMoreButton, ReadMoreSearch } from "../../../common/helpers/styling/Buttons";
import { Ul, Li } from "../../../common/helpers/styling/List";
import { TagContainer, TagItem, TagLink } from '../../../common/helpers/styling/Tags';
import { InputText } from '../../../common/helpers/styling/Inputs';
import { SubTopic } from "../../../models/SubTopic";
import { Topic } from "../../../models/Topic";
import { SearchOptionGroup } from "../../../models/SearchOptions";
import { SearchResult } from "../../../models/SearchResult";
import { SearchType } from "../../../models/enums/SearchType";
import { getSearchResults, getSearchType, requiredDataForPhraseSearch, requiredDataForTagSearch } from "../../../common/helpers/searchHelpers";
import { LoadingIcon } from "../../../common/helpers/styling/LoadingIcon";
import { SubLogo } from '../../shared/Logo';
import { SearchDataType } from "../../../models/enums/SearchDataType";
import { getAppInsights } from '../../../common/helpers/TelemetryService';
import { myMSALObj } from '../../../common/authConfig';
import { checkIfEmpty } from '../../../common/helpers/generalHelpers';
import { CustomError } from "../../../models/Error";

//#region Props & State
interface ISearchResultsStoreProps {
    loadedSearchData: Array<SearchDataType>;
    subTopics: Array<SubTopic>;
    topics: Array<Topic>;
    searchOptions: Array<SearchOptionGroup>; 
    siteError: CustomError;
}

interface ISearchResultsStoreDispatchProps {

}

interface ISearchResultsOwnProps {}

type ISearchResultsProps = ISearchResultsStoreProps & ISearchResultsStoreDispatchProps & ISearchResultsOwnProps;

interface ISearchResultsState {
    searchDataLoaded: boolean;
    searchPhrase?: string;
    searchResults: Array<SearchResult>;
    searchTag?: string;
    searchType: SearchType;
    urlStringPhrase: string;
    isLoaded: boolean;
    majorError: boolean;
}
 
class SearchResultsPage extends React.Component<RouteComponentProps<any> & ISearchResultsProps, ISearchResultsState> {    
    hasMounted: boolean = false;
    constructor(props: any) {
        super(props);

        // Set Default States
        this.state = {
            searchDataLoaded: false,
            searchPhrase: ``,
            searchResults: [],
            searchTag: ``,
            searchType: SearchType.Phrase,
            urlStringPhrase: ``,
            isLoaded: false,
            majorError: false
        };

        getAppInsights().trackPageView();
    }

    //#region LifeCycle Methods
    componentDidMount() {
        this.hasMounted = true;
        this.setSearchResults();

        if (this.props.topics.length !== 0 ) {
            let allHtmlLoaded: Array<boolean> = [];
            this.props.topics.forEach((topic:Topic, index:number) => { 
                let isLoaded:boolean = checkIfEmpty(topic.htmlContent);
                allHtmlLoaded.push(isLoaded);
            });
            if(!allHtmlLoaded.some(item => item === true)){
                this.setState({ isLoaded: true });
            }  
        }     

    }

    componentDidUpdate(prevProps: ISearchResultsProps, prevState: ISearchResultsState) {
        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
        let searchPath:string = this.props.location.pathname + this.props.location.search;

        if (prevProps.loadedSearchData !== this.props.loadedSearchData) {
            this.setSearchResults(); 
        }
        // When the SearchResults?tag URL doesnt match prevoius state. This will come into play when user clicks on tag from within component
        if (queryStringResult && queryStringResult.tag && queryStringResult.tag !== prevState.urlStringPhrase ) {
             this.setSearchResults();
        }

        if (this.props.topics !== prevProps.topics && prevProps.topics.length !== 0 ) {
            let allHtmlLoaded: Array<boolean> = [];
            this.props.topics.forEach((topic:Topic, index:number) => { 
                let isLoaded:boolean = checkIfEmpty(topic.htmlContent);
                allHtmlLoaded.push(isLoaded);
            });

            if(!allHtmlLoaded.some(item => item === true)){
                this.setState({ isLoaded: true });
            }          
        }

        if ((prevProps.siteError.errorTitle !== this.props.siteError.errorTitle) && (this.props.siteError.errorTitle !== ``)) {
            if (this.props.siteError.showErrorPage) {
                this.setState({ majorError: true });
            }
        }

    }

    componentWillUnmount() {
        this.hasMounted = false;
    }
    //#endregion

    render () {

        if (this.state.majorError) {
            return <Redirect to='/Error'/>;
        }
        return (
            <>
            <Row style={{alignContent:`start`, flexGrow: 3 }} grow filled reverseOrderMobile>
                <Col xs={12} md={3} lg={2} hideMobile hideTablet hideOnPrint>
                     &nbsp;
                </Col>
                <Col pr={12} xs={12} md={9} lg={8} filled grow>
                    <Padding large>
                            <H5 style={{marginTop: 0 }}>
                                <SubLogo />
                            </H5>
                        <H1>
                            {`Search Results ${this.state.searchPhrase ? `for "${this.state.searchPhrase}" ` : ``}${this.state.searchTag ? `| Tag: "${this.state.searchTag}"` : ``}`}
                        </H1>             
                        {!this.state.searchDataLoaded ? <LoadingIcon/> :                    
                            this.state.searchResults.length ? <Ul>
                                {this.state.searchResults.map((result, index) => {
                                    return (
                                        <Li key={index}>
                                            <ReadMoreSearch to={result.url}>{result.title}</ReadMoreSearch>     
                                            {((result.tags !== undefined) && (result.tags.length > 0)) && 
                                                <TagContainer small>
                                                    {result.tags.map((tag, tagIndex) => {
                                                        return (
                                                                <TagItem key={tagIndex}>
                                                                    <TagLink to={`/SearchResults?tag=${tag}`}>
                                                                        {tag}     
                                                                    </TagLink>
                                                                </TagItem>
                                                            );
                                                        })
                                                    }
                                                </TagContainer>
                                            }
                                            <ReadMoreButton to={result.url}> Read More</ReadMoreButton>
                                        </Li>
                                    );
                                })}
                            </Ul>
                            : <p>No Results Found</p>
                        }
                    </Padding>

                </Col>
                <Col xs={12} md={3} lg={2} style={{paddingTop: `100px` }} hideOnPrint>
                    <InputText 
                        options={this.props.searchOptions}  
                        placeholder='Search Alas Edge'
                        isClearable 
                        onChange={(value:any) => this.onSearchTextFieldChange(value)}
                        isDisabled={!this.props.searchOptions.length}    
                    />  
                </Col>
            </Row>

            </>
        );
    }

    // Handle on Intial Load and change of URL
    private setSearchResults = (): void => {        
        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
        if (queryStringResult && (queryStringResult.tag || queryStringResult.phrase)) {
            let phrase: string = queryStringResult.phrase ? queryStringResult.phrase.toString() : ``;
            let tag: string = queryStringResult.tag ? queryStringResult.tag.toString() : ``;
            let searchType: SearchType = getSearchType(phrase?.toString(), tag?.toString());
            let requiredData: Array<SearchDataType> = tag && !phrase ? requiredDataForTagSearch : requiredDataForPhraseSearch;
            let searchDataLoaded: boolean = requiredData.every(rd => this.props.loadedSearchData.includes(rd));
            
            this.setState({
                searchDataLoaded,
                searchPhrase: phrase,
                searchResults: searchDataLoaded ? getSearchResults(this.props.loadedSearchData, this.props.topics, this.props.subTopics, phrase, tag) : [],
                searchTag: tag,
                searchType,
                urlStringPhrase: tag
            });
        }
    }

    // Handle Change of Search Text Field
    private onSearchTextFieldChange = (selection: any): void => {    
        if(!selection){      
            return;
        }
        const selectionLabel: string = selection.label;  
        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
        if (queryStringResult && (queryStringResult.tag || queryStringResult.phrase)) {
            this.props.history.replace(`/SearchResults?phrase=${selectionLabel}`);
            let phrase: string = selectionLabel ? selectionLabel : ``;
            let tag: string = queryStringResult.tag ? queryStringResult.tag.toString() : ``;
            let searchType: SearchType = getSearchType(phrase?.toString(), tag?.toString());
            let requiredData: Array<SearchDataType> = tag && !phrase ? requiredDataForTagSearch : requiredDataForPhraseSearch;
            let searchDataLoaded: boolean = requiredData.every(rd => this.props.loadedSearchData.includes(rd));
            
            this.setState({
                searchDataLoaded,
                searchPhrase: phrase,
                searchResults: searchDataLoaded ? getSearchResults(this.props.loadedSearchData, this.props.topics, this.props.subTopics, phrase, tag) : [],
                searchTag: tag,
                searchType,
                urlStringPhrase: phrase
            });
        }

    }

}

function mapStateToProps(state: State) {
    return {
        loadedSearchData: state.loadedSearchData,
        subTopics: state.allSubTopics,
        topics: state.allTopics,
        searchOptions: state.allSearchOptions,
        siteError: state.siteError
    };
}
  
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        
    },        
    dispatch);  
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsPage);
