import styled from "styled-components";
 
interface ColProps {
    pr?: number;
    xs: number;
    md: number;
    lg?: number;
    padding?:string;
    displayFlex?: boolean;
    filled?: boolean;
    hideMobile?: boolean;
    hideOnPrint?:boolean;
    hideTablet?:boolean;
    grow?:boolean;
}

interface RowProps {
  centerContent?: boolean;
  fullWidth?: boolean;
  grow?:boolean;
  paddCol?:boolean;
  filled?: boolean;
  reverseOrderMobile?:boolean;
}

interface PaddingProps {
  large?: boolean;
  noPaddMobile?:boolean;
  left?:boolean;
  right?:boolean;
  noPaddHandHeld?:boolean;
}
  
export const Row = styled.div<RowProps>`
  display: flex; width: 100%; flex-wrap:wrap; box-sizing:border-box;justify-content: flex-start;
  margin: 0 auto;
  max-width:${({ fullWidth, theme: { colors } }) => fullWidth ? `100%` :  `1900px` };


  justify-content:${(props: any) => props.centerContent ? `center` : `flex-start`};
  flex-grow: ${(props: any) => props.grow ? 3 : `unset`};

  background:${({ filled, theme: { colors } }) => filled ? colors.greys.xxlight : `transparent` };
  ${({ paddCol, theme: { padding } }) => paddCol ? `> div{padding:20px;}` : `` };

  @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
    flex-flow:${(props: any) => props.reverseOrderMobile ? `column-reverse` : ``};
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.tablet} { 
    flex-flow:${(props: any) => props.reverseOrderMobile && `unset`};
  }
   
`;

export const Padding = styled.div<PaddingProps>`
  padding:${({ large, theme: { padding } }) => large ? padding.large : `` };
  padding:${({ left, theme: { padding } }) => left ? `0 0 0 ` + padding.small : `` }; 
  padding:${({ right, theme: { padding } }) => right ? `0 ` + padding.small + ` 0 0` : `` };
  
  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
    ${({ noPaddHandHeld }) => noPaddHandHeld && 
    ` padding:0`
    };
    padding:${({ large, theme: { padding } }) => large ? padding.small : `` };
  }

`;

export const Col = styled.div<ColProps>`
  flex-basis: ${props => (props.xs / 12) *  100 + `%` };
  max-width: ${props => (props.xs / 12) *  100 + `%` };
  position: relative;
  padding: ${ props => props.padding ? props.padding : `0px`}; 
  display: ${ props => props.displayFlex ? `flex` : `initial`}; 
  align-items: ${ props => props.displayFlex ? `center` : `normal`};
  background:${({ filled, theme: { colors } }) => filled ? colors.white : `transparent` };
  ${({ grow }) => grow ? `flex-grow:3;height:100%;box-shadow:0px 0px 10px rgba(0,0,0,0.07);`: `` };
 
  @media ${({ theme: { deviceSizes } }) => deviceSizes.mobile} { 
    flex-basis: ${props => (props.xs / 12) *  100 + `%` };
    max-width: ${props => (props.xs / 12) *  100 + `%` };
    ${({ hideMobile }) => hideMobile ? `display:none`: `` };
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.tablet} { 
    flex-basis: ${props => (props.md / 12) *  100 + `%` };
    max-width: ${props => (props.md / 12) *  100 + `%` };
    ${({ hideMobile }) => hideMobile && 
      ` display:initial`
    };
    ${({ hideTablet }) => hideTablet ? `display:none`: `` };
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.laptop} { 
    flex-basis: ${props => (props.lg ? props.lg / 12 : props.md  / 12) *  100 + `%` };
    max-width: ${props => (props.lg ? props.lg / 12 : props.md  / 12) *  100 + `%` };
    ${({ hideTablet }) => hideTablet && 
      ` display:initial`
    };
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.print} { 
    ${({ hideOnPrint }) => hideOnPrint ? `display:none`: `` };
    flex-basis: ${props => (props.pr ? props.pr / 12 : 1) *  100 + `%` };
    max-width: ${props => (props.pr ? props.pr / 12 : 1) *  100 + `%` };
    box-shadow:unset;
  }


 
  
`;