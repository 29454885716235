import * as moment from "moment";
import * as AzureStorageBlob from "@azure/storage-blob";

export interface SubTopicMetaData {
    lastModified: moment.Moment;
    powerBIInstructions?: string;
    powerBIPageName?: string;
    powerBIReportId?: string; // TODO: Will there potentially be multiple report ids for different sub-topics, or does the whole site share 1 reportId?
    title: string;
}

export class SubTopic {
    constructor(
        public blob: AzureStorageBlob.BlobItem,
        public metadata: SubTopicMetaData | undefined = undefined,
        public htmlContent: any = undefined,
        public visible: boolean = false,
    ) {}
}