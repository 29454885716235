import { azureAccount, topicTOC, topicAssets, tocAccess, assetsAccess, articlesAccess, articlesTable } from '../common/constants/config';
import axios from 'axios';
const tocTableApiUrl = `https://${azureAccount}.table.core.windows.net/${topicTOC}`;
const topicAssetsTableApiUrl = `https://${azureAccount}.table.core.windows.net/${topicAssets}`;
const articlesTableApiUrl = `https://${azureAccount}.table.core.windows.net/${articlesTable}`;

const headres = {
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
};

export function getTopicSubTopicMap() {    
    const url = `${tocTableApiUrl}${tocAccess}`; 
    return axios.get(url, headres);
}

export function getTopicAssetsMap() { 
    const url = `${topicAssetsTableApiUrl}${assetsAccess}`; 
    return axios.get(url, headres);
}

export function getArticlesMap() { 
    const url = `${articlesTableApiUrl}${articlesAccess}`; 
    return axios.get(url, headres);
}

