import * as React from 'react';
import { RouteComponentProps,Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from "styled-components";
import { Topic } from '../../../models/Topic';
import { Article } from '../../../models/Article';
import { checkIfEmpty } from '../../../common/helpers/generalHelpers';
import { State } from '../../../store/rootReducer';
import {Row,Col, Padding} from "../../../common/helpers/styling/ResponsiveGrid";
import {H1, H5, H2} from "../../../common/helpers/styling/Typography";
import {LoadingIcon} from "../../../common/helpers/styling/LoadingIcon";
import Button from '../../../common/helpers/styling/Buttons';
import { InputText } from '../../../common/helpers/styling/Inputs';
import { getTitleText, getTitleUrl } from '../../../common/helpers/stringHelpers';
import { sortArrayElementsByPriority } from '../../../common/helpers/generalHelpers';
import { SubTopic } from '../../../models/SubTopic';
import { SubLogo } from '../../shared/Logo';
import { SearchOptionGroup } from "../../../models/SearchOptions";
import { SearchDataType } from '../../../models/enums/SearchDataType';
import { myMSALObj } from '../../../common/authConfig';
import { azureImagePath } from '../../../common/constants/config';
import { getAppInsights } from '../../../common/helpers/TelemetryService';
import { CustomError } from "../../../models/Error";

//#region Props & State
interface ITopicStoreProps {
    loadedSearchData: Array<SearchDataType>;
    subTopics: Array<SubTopic>;
    topics: Array<Topic>;
    articles: Array<Article>;
    searchOptions: Array<SearchOptionGroup>;
    siteError: CustomError;
}

interface ITopicState {
    isLoaded: boolean;
    majorError: boolean;
}
//#endregion

export const ReadMoreButton = styled.a`
    font-family:${({ theme: { fonts } }) => fonts.body}, 'serif';text-transform: uppercase; 
    color:${({ theme: { colors } }) => colors.primary.main}; cursor:pointer;font-size: 15px;font-weight: bold;       
`;

export const ReadMoreTo = styled(Link)`
    font-family:${({ theme: { fonts } }) => fonts.body}, 'serif';text-transform: uppercase; 
    color:${({ theme: { colors } }) => colors.primary.main}; cursor:pointer;font-size: 15px;font-weight: bold;       
`;

export const InnerHTML = styled.div`
    & > div > img{width:100%;}     
`;

export const LinkToStyled = styled(Link)`
text-decoration:none;

color:${({ theme: { colors } }) => colors.greys.dark};
    &:visited {color:${({ theme: { colors } }) => colors.greys.dark};}    
    &:active {color:${({ theme: { colors } }) => colors.greys.dark};} 
`;

class Dashboard extends React.Component<RouteComponentProps<any> & ITopicStoreProps, ITopicState> {
    hasMounted: boolean = false;
    constructor(props: any) {
        super(props);
       
        // Set Default States
        this.state = {          
            isLoaded: false,
            majorError: false
        };

        getAppInsights().trackPageView();
    }

    componentDidMount() {
        this.hasMounted = true;
       
        if (this.props.topics.length !== 0 ) {
            let allHtmlLoaded: Array<boolean> = [];
            this.props.topics.forEach((topic:Topic, index:number) => { 
                let isLoaded:boolean = checkIfEmpty(topic.htmlContent);
                allHtmlLoaded.push(isLoaded);
            });
            if(!allHtmlLoaded.some(item => item === true)){
                this.setState({ isLoaded: true });
            }  
        }
    }
    
    componentWillUnmount() {
        this.hasMounted = false;
    }

    componentDidUpdate(prevProps: ITopicStoreProps) {
        // Handle errors
        if ((prevProps.siteError.errorTitle !== this.props.siteError.errorTitle) && (this.props.siteError.errorTitle !== ``)) {
            if (this.props.siteError.showErrorPage) {
                this.setState({ majorError: true });
            }
        }
        
        if (this.props.topics !== prevProps.topics && prevProps.topics.length !== 0 ) {
            let allHtmlLoaded: Array<boolean> = [];
            this.props.topics.forEach((topic:Topic, index:number) => { 
                let isLoaded:boolean = checkIfEmpty(topic.htmlContent);
                allHtmlLoaded.push(isLoaded);
            });

            if(!allHtmlLoaded.some(item => item === true)){
                this.setState({ isLoaded: true });
                
            }          
        }
    }

    render () {
        if (this.state.majorError) {
            return <Redirect to='/Error'/>;
        }
        return (
            <>
                <Row style={{alignContent:`start`, flexGrow: 3}} grow filled reverseOrderMobile>
                    <Col xs={12} md={2} lg={2} hideTablet hideMobile style={{paddingTop: 110 }} hideOnPrint>
                         
                    </Col>
                    <Col pr={12} xs={12} md={9} lg={8} filled grow >
                        <Padding large>
                            <H5 style={{marginTop: 0 }}>
                                <SubLogo />
                            </H5>
                            <H2 mainTitle>Topics</H2>
                            <Row style={{alignContent:`start`,justifyContent:`flex-start`, flexGrow: 3 }} grow>
                                 {/*=== Show First Two only ===*/}
                                 {(this.props.topics.length >= 0 && this.state.isLoaded) ? (
                                        
                                    sortArrayElementsByPriority(this.props.topics).filter(t => t.hasAccess).map((topic:Topic, index:number) => {
                                        return (      
                                            <Col key={index} xs={12} md={6}>
                                                {topic.featured ? (
                                                    <Padding right noPaddHandHeld>
                                                        <H1>
                                                            <LinkToStyled to={`/Topic?title=${getTitleUrl(topic)}`}>
                                                                {topic ? getTitleText(topic) : ``}
                                                            </LinkToStyled> 
                                                        </H1>
                                                        {(topic.summary !== ``) && (
                                                            <p>{topic.summary}</p>
                                                        )}
                                                        {this.getTopicImage(topic.thumbnail, getTitleText(topic))}                
                                                        <p style={{textAlign: `right`, margin: 0 }}>
                                                            <ReadMoreTo to={`/Topic?title=${getTitleUrl(topic)}`}>Read More</ReadMoreTo>
                                                        </p>
                                                    </Padding>
                                                ) : (
                                                    <Button url={`/Topic?title=${getTitleUrl(topic)}`}  >
                                                        {topic ? getTitleText(topic) : ``}
                                                    </Button>
                                                )}  
 
                                            </Col>
                                        );
                                    })

                                 ) : (<div style={{margin:`0 auto`}}><LoadingIcon/></div>)}
 
                            </Row>
                            {/* ======= */}
                            <H2 mainTitle>Data Ethics and Privacy</H2>
                            <Row id='MDVToAlasEdge' style={{alignContent:`start`,justifyContent:`flex-start`, flexGrow: 3 }} grow>
                                {this.props.articles.length >= 0 ? (                                       
                                    sortArrayElementsByPriority(this.props.articles).map((article:Article) => {
                                        return (      
                                            <Col key={article.key} xs={12} md={4}> 
                                                <Padding right noPaddHandHeld>
                                                    {article.title != null ? (
                                                        <H1>{article.title}</H1>
                                                    ) : (
                                                        <H1>{article.key}</H1>
                                                    )}
                                                    {article.urlLink != null && (
                                                        <p style={{textAlign: `right`, margin: 0 }}>
                                                            <ReadMoreButton href={article.urlLink}  target={`_blank`}>Read More</ReadMoreButton>
                                                        </p>
                                                    )}
                                                    {article.thumbnail != null && (
                                                        <img 
                                                            style={{width:`100%`}}
                                                            src={`${azureImagePath}${article.thumbnail}`} 
                                                            alt={article.title}
                                                        />
                                                    )} 
                                                </Padding>
                                            </Col>
                                        );
                                    })

                                ) : (<div style={{margin:`0 auto`}}><LoadingIcon/></div>)}

                            </Row>
                            {/* ======= */}
                             
                        </Padding>

                    </Col>
                    <Col xs={12} md={3} lg={2} style={{paddingTop: `100px` }} hideOnPrint>
                        <InputText 
                            options={this.props.searchOptions}  
                            placeholder='Search Alas Edge'
                            isClearable 
                            onChange={(value:any) => this.onSearchTextFieldChange(value)}
                            isDisabled={!this.props.searchOptions.length}  
                        />               
                    </Col>
                </Row>
            </>
        );
    }

    //#region Arrow Function Region
    // Handle Change of Search Text Field
    private onSearchTextFieldChange = (selection: any): void => {    
        if(!selection){      
            return;
        }
        const selectionLabel: string = selection.label;  
         
        if (this.props.searchOptions.length) {
            // all we want to do here is pass the phrase through history and let the search page handle what happens from there.
            this.props.history.replace(`/SearchResults?phrase=${selectionLabel}`);           
        }

    }
    // Return Topic Main Image  
    private getTopicImage = (imagePath: string | undefined, topicAlt: string ): JSX.Element => {   
        if(!imagePath || imagePath === `` || imagePath === undefined){      
            return <></>;
        } else {
            return (
                <img src={imagePath} alt={topicAlt} style={{width:`100%`}}/>
            );
        }

    }
    //#endregion

}
function mapStateToProps(state: State) {
    return {
        loadedSearchData: state.loadedSearchData,
        subTopics: state.allSubTopics,
        topics: state.allTopics,
        articles: state.allArticles,
        searchOptions: state.allSearchOptions,
        siteError: state.siteError
    };
}
  
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        
    },        
    dispatch);  
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);