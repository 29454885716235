import styled from "styled-components";
import Select from "react-select";

interface InputProps {
    maxWidth?: string;
}
 
export const InputText = styled(Select)`
width: 90%;
 
margin: 0 auto;  
 
`; 
  