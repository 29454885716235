import { SubTopic } from "../../models/SubTopic";
import { Reducer } from "redux";
import { UPSERT_SUBTOPICS } from "../actions/actionTypes";

export const subTopicReducer: Reducer<Array<SubTopic>> = (state = [], action) => {
    switch (action.type) {
        case UPSERT_SUBTOPICS:
            let newSubTopics: Array<SubTopic> = action.payload;
            let newState: Array<SubTopic> = [...state];
            newSubTopics.forEach(newSubTopic => {
                
                // Ensure subTopics are NEVER saved to app state as visible (to guarantee user will always have to manually trigger individual subTopics to become visible within a given topic)
                let newHiddenSubTopic = {...newSubTopic};
                newHiddenSubTopic.visible = false;

                let originalSubTopicIndex = state.findIndex(subTopic => {
                    return subTopic.blob.name === newSubTopic.blob.name;
                });
                if (originalSubTopicIndex > -1) {
                    newState.splice(originalSubTopicIndex, 1, newHiddenSubTopic);
                } else {
                    newState.push(newHiddenSubTopic);
                }
            }); 
            return newState;
        default:
            return state;
    }
};