import * as AzureStorageBlob from "@azure/storage-blob"; // Docs @ https://docs.microsoft.com/en-us/javascript/api/@azure/storage-blob/blobserviceclient?view=azure-node-latest
import { azureAccount, subTopicContainer, topicContainer } from '../common/constants/config';
import axios from 'axios';
const blobApiUrl = `https://${azureAccount}.blob.core.windows.net`;
const blobServiceClient = new AzureStorageBlob.BlobServiceClient(blobApiUrl);

const headres = {
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
};

//#region Topics
export async function getTopics() {
    const containerClient = blobServiceClient.getContainerClient(topicContainer);
    let iter = await containerClient.listBlobsFlat();
    let blobs: Array<AzureStorageBlob.BlobItem> = [];
    for await (const blob of iter) {
        blobs.push(blob);
    }
    return blobs;
}

export function getTopicContent(fileName: string) {
    const url = `${blobApiUrl}/${topicContainer}/${fileName}`;
    return axios.get(url, headres);
}

export function getTopicMetaData(fileName: string) {
    const url = `${blobApiUrl}/${topicContainer}/${fileName}?comp=metadata`;
    return axios.get(url, headres);
}
//#endregion

//#region SubTopics
export async function getSubTopics() {
    const containerClient = blobServiceClient.getContainerClient(subTopicContainer);
    let iter = await containerClient.listBlobsFlat();
    let blobs: Array<AzureStorageBlob.BlobItem> = [];
    for await (const blob of iter) {
        blobs.push(blob);
    }
    return blobs;
}

export function getSubTopicContent(fileName: string) {
    const url = `${blobApiUrl}/${subTopicContainer}/${fileName}`;
    return axios.get(url, headres);
}

export function getSubTopicMetaData(fileName: string) {
    const url = `${blobApiUrl}/${subTopicContainer}/${fileName}?comp=metadata`;
    return axios.get(url, headres);
}
//#endregion