 export interface ErrorJson {
    errorTitle: string;
    statusCode: number;
    errorMessage: string;
    showErrorPage: boolean;
}

export class CustomError {

    public static fromJson = (error: ErrorJson) => {
        if (error) {
            return new CustomError(
                error.errorTitle,
                error.statusCode,
                error.errorMessage,
                error.showErrorPage
            );
        }
        return new CustomError();
    }

    constructor(
        public errorTitle: string = ``,
        public statusCode: number | undefined = undefined,
        public errorMessage: string = ``,
        public showErrorPage: boolean = false
        
    ) {}
}