import * as React from 'react';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { ReactNode } from "react";
import { MdArrowForward } from "react-icons/md";

interface ButtonProps {
    children: ReactNode;
    maxWidth?: string;
    url?:string;
    style?: any;
}
 
export const ReadMoreButton = styled(Link)`
    font-family:${({ theme: { fonts } }) => fonts.body}, 'serif';text-transform: uppercase; 
    color:${({ theme: { colors } }) => colors.primary.main}; cursor:pointer;font-size: 16px;  font-weight: bold; padding-top: 10px;
    display: block;     
`;

export const ReadMoreTo = styled(Link)`
    font-family:${({ theme: { fonts } }) => fonts.body}, 'serif';text-transform: uppercase; 
    color:${({ theme: { colors } }) => colors.primary.main}; cursor:pointer;font-size: 15px;font-weight: bold;       
`;

export const ReadMoreSearch = styled(Link)`
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
    font-weight:600;font-size: 28px; display:block;
    padding: 0 0 10px; text-decoration: none;
    color: ${({ theme: { colors } }) => colors.primary.main};       
`;

export const ButtonStyled = styled(Link)`
  cursor: pointer;
  font-weight: normal;
  display: flex;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: space-between; 
  background: ${({ theme: { colors } }) => colors.greys.xmedium}; 
  color: ${({ theme: { colors } }) => colors.primary.main}; text-transform:uppercase;
  border:none; padding:10px;font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;text-decoration:none;transition: background .2s ease-in;
    &:hover{
        background: ${({ theme: { colors } }) => colors.primary.main};
        color: ${({ theme: { colors } }) => colors.white};  
    }
    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
        margin-right: 0px;
    }

`;
 
const Button = ({ children, ...props }: ButtonProps) => (
    <ButtonStyled to={props.url ? props.url : `#`}>
        {children}
        <MdArrowForward style={{marginTop:`10px`}}/>
    </ButtonStyled>
);
export default Button;