import * as Msal from 'msal';
import { Environment } from '../models/enums/Environment';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let environment = Environment.DEV;
let subdomain = `dev`;
let signUpSignInAuth;
let forgotPasswordAuth;
let clientId;
let b2cScopes;
let telemetryInstrumentationKey;
const hostname = window && window.location && window.location.hostname;

// Config for localhost
if (hostname === `localhost` || hostname === `alasdia-uscu-dev-edge.azurewebsites.net` || hostname === `alas-uscu-dev-alasedge.azurewebsites.net` || hostname === `edge-dev.alas.com`) {
    // DEV config
    environment = Environment.DEV;
    signUpSignInAuth = `https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_signup_signin/`;
    forgotPasswordAuth = `https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_PasswordReset/`;
    clientId = `4f66306a-a0c0-4734-a86f-9f3159a55152`;
    b2cScopes = [`https://ALASB2CDev.onmicrosoft.com/ALASEdge/ALASEdge.Read`];
    telemetryInstrumentationKey = `49b9dbb1-3720-449b-bbd6-64c6171727f0`;
    subdomain = `dev`;
} else if (hostname === `alasdia-uscu-test-edge.azurewebsites.net` || hostname === `alas-uscu-test-alasedge.azurewebsites.net` || hostname === `edge-test.alas.com`) {
    // Test config 
    environment = Environment.TEST;
    signUpSignInAuth = `https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_signup_signin_test/`;
    forgotPasswordAuth = `https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_PasswordReset/`;
    clientId = `4f66306a-a0c0-4734-a86f-9f3159a55152`;
    b2cScopes = [`https://ALASB2CDev.onmicrosoft.com/ALASEdge/ALASEdge.Read`];
    telemetryInstrumentationKey = `8398e44c-6475-4afb-b5b5-8cc563ca1e5b`;
    subdomain = `review`;
} else if (hostname === `alas-uscu-uat-alasedge.azurewebsites.net` || hostname === `edge-uat.alas.com`) {
    // UAT config 
    environment = Environment.UAT;
    signUpSignInAuth = `https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_signup_signin_uat/`;
    forgotPasswordAuth = `https://ALASB2CDev.b2clogin.com/tfp/ALASB2CDev.onmicrosoft.com/B2C_1A_PasswordReset/`;
    clientId = `4f66306a-a0c0-4734-a86f-9f3159a55152`;
    b2cScopes = [`https://ALASB2CDev.onmicrosoft.com/ALASEdge/ALASEdge.Read`];
    telemetryInstrumentationKey = `7ea064d3-7722-4b50-aad1-9ec1e522925a`;
    subdomain = `review`;
} else if (hostname === `alasdia-uscu-prd-edge.azurewebsites.net` || hostname === `alas-uscu-prd-alasedge.azurewebsites.net` || hostname === `edge.alas.com`) {
    // PROD config
    environment = Environment.PROD;
    signUpSignInAuth = `https://ALASB2CProd.b2clogin.com/tfp/ALASB2CProd.onmicrosoft.com/B2C_1A_signup_signin/`;
    forgotPasswordAuth = `https://ALASB2CProd.b2clogin.com/tfp/ALASB2CProd.onmicrosoft.com/B2C_1A_PasswordReset/`;
    clientId = `f6884b4a-2081-4fed-9a96-25c4e039df53`;
    b2cScopes = [`https://alasb2cprod.onmicrosoft.com/ALASEdge/ALASEdge.Read`];
    telemetryInstrumentationKey = `6564cbd3-161f-4cf6-b6b3-d4ec5f0d56c0`;
    subdomain = `www`;
} else {
    // eslint-disable-next-line no-throw-literal
    throw `Environment not implemented: ${hostname}`;
}

export const authorities = {
    signUpSignIn: {
        authority: signUpSignInAuth
    },
    forgotPassword: {
        authority: forgotPasswordAuth
    }
};

export const msalConfig = {
    auth: {
        validateAuthority: false,
        cacheLocation: `sessionStorage`,
        clientId: clientId,
        authority: authorities.signUpSignIn.authority, 
        b2cScopes: b2cScopes,
    }
};

// create UserAgentApplication instance
export const myMSALObj = new Msal.UserAgentApplication(msalConfig);

// request to signin - returns an idToken
export const loginRequest = {
    scopes: [`openid`, `profile`],
};

// request to acquire a token for resource access
export const tokenRequest = {
    scopes: msalConfig.auth.b2cScopes
};
//Application insight instrumentation key
export const Telemetry_Instrumentation_Key = telemetryInstrumentationKey;

export const Subdomain = subdomain;