import styled from "styled-components";
import { Link } from 'react-router-dom';

interface TagProps {
    padding?:string;
    displayFlex?: boolean;
    filled?: boolean;
    small?: boolean;
}

interface TagItemProps {
  paddCol?:boolean;
  className?:string | boolean | undefined;
  // any other props that come into the component
}
  
export const TagContainer = styled.ul<TagProps>`
margin: 0;
display: flex;
padding: 8px;
flex-wrap: wrap;
list-style: none;
justify-content: flex-start;
background-color:${({ theme: { colors } }) => colors.greys.xxlight};


${({ small }) => small ? `background-color:transparent;padding:4px 0px; & li{height: 24px;}`: `` };

`;

export const TagItem = styled.li<TagItemProps>`
border: none;
cursor: pointer;
height: 25px;
display: inline-flex;
outline: 0;
padding: 0;
align-items: center;
 white-space: nowrap;
border-radius: 4px;
vertical-align: middle;
justify-content: center;
text-decoration: none;
margin-right: 30px;

background-color:${({ theme: { colors } }) => colors.greys.medium};
position:relative;

  &::after {
      content:'';
      display: inline-block;
      width:0;
      height:0;
      border:12px solid transparent;
      vertical-align: middle;
      border-left-color: ${({ theme: { colors } }) => colors.greys.medium};
      
      position:absolute; right: -23px;
  }

  &:hover, &.isActive{
    background-color:${({ theme: { colors } }) => colors.primary.main};
    color:${({ theme: { colors } }) => colors.white};
    &::after {
        border-left-color: ${({ theme: { colors } }) => colors.primary.main};
    }
    & a{ color:${({ theme: { colors } }) => colors.white}!important; }
  }
 
  @media ${({ theme: { deviceSizes } }) => deviceSizes.print} { 
    background-color:transparent;
    background:transparent!important;
    &::after{display:none;}
    font-style: italic;margin-right:0px;
  }

  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} {
    margin-bottom: 10px;
  }


`;

export const TagLink = styled(Link)<TagItemProps>`
padding: 3px 12px;text-transform: uppercase; text-decoration: none;
color:${({ theme: { colors } }) => colors.greys.xdark}!important;
font-size: 11px;
`;
