import { Environment } from '../../models/enums/Environment';

let appService;
let apiBaseURL;
let imageBaseURL;
let account;
let alasEdgeAssetsAccess;
let alasEdgeTOCAccess;
let alasEdgeArticlesAccess;
let environment = Environment.DEV;
const hostname = window && window.location && window.location.hostname;

if (hostname === `localhost` || hostname === `alasdia-uscu-dev-edge.azurewebsites.net` || hostname === `alas-uscu-dev-alasedge.azurewebsites.net` || hostname === `edge-dev.alas.com`) {
    // Localhost environment config
    account = `alasdiauscudev`;
    appService = `alasdia-uscu-dev-edge`;
    apiBaseURL = `https://${appService}.azurewebsites.net`;
    imageBaseURL = `https://${account}.blob.core.windows.net`; 
    environment = Environment.DEV;
    alasEdgeAssetsAccess = `?sp=r&st=2021-01-26T13:45:01Z&se=2121-01-27T13:45:00Z&sv=2019-12-12&sig=aRCTgyO874gw439wIpDjiONR6eUvcamPDesKwg%2BhrW4%3D&tn=AlasEdgeAssets`;
    alasEdgeTOCAccess = `?sp=r&st=2021-01-26T13:45:54Z&se=2121-01-27T13:45:00Z&sv=2019-12-12&sig=oBQfux4kWSj6EVB%2BetNKp6gQPf385bSP28%2BBV7chb4c%3D&tn=AlasEdgeTOC`;
    alasEdgeArticlesAccess = `?sp=r&st=2021-02-15T16:01:05Z&se=2121-02-16T16:01:00Z&sv=2019-12-12&sig=hoaupIMvD1nsdvEXIKukY%2Be28mbvrSaDdTQF41f2CtY%3D&tn=AlasEdgeArticles`;
} else if (hostname === `alasdia-uscu-test-edge.azurewebsites.net` || hostname === `alas-uscu-test-alasedge.azurewebsites.net` || hostname === `edge-test.alas.com`) { 
    // TEST environment config
    account = `alasdiauscutest`;
    appService = `alasdia-uscu-test-edge`;
    apiBaseURL = `https://${appService}.azurewebsites.net`; 
    imageBaseURL = `https://${account}.blob.core.windows.net`; 
    environment = Environment.TEST;
    alasEdgeAssetsAccess = `?sp=r&st=2021-01-26T14:02:32Z&se=2121-01-27T14:02:00Z&sv=2019-12-12&sig=WN2bS8chRvwb6GAKGXmmq4%2BzOu7U9yv2AHsRvp3pHWc%3D&tn=AlasEdgeAssets`;
    alasEdgeTOCAccess = `?sp=r&st=2021-01-26T14:03:16Z&se=2121-01-27T14:03:00Z&sv=2019-12-12&sig=GgzZrVcbpBoDwIbdaEo%2BdgTpfZGxdebV1qcMBVuEdHs%3D&tn=AlasEdgeTOC`;
    alasEdgeArticlesAccess = `?sp=r&st=2021-02-16T21:09:10Z&se=2121-02-17T21:09:00Z&sv=2020-02-10&sig=9ONp%2BY5%2BDbQaKmmLlaXIduxOGt4DoQCgn46%2F%2F8cma64%3D&tn=AlasEdgeArticles`;
} else if (hostname === `alas-uscu-uat-alasedge.azurewebsites.net` || hostname === `edge-uat.alas.com`) { 
    // UAT environment config 
    account = `alasuscuuat`;
    appService = `alas-uscu-uat-alasedge`;
    apiBaseURL = `https://${appService}.azurewebsites.net`; 
    imageBaseURL = `https://${account}.blob.core.windows.net`; 
    environment = Environment.UAT;
    alasEdgeAssetsAccess = ``;
    alasEdgeTOCAccess = ``;
    alasEdgeArticlesAccess = ``;
} else if (hostname === `alasdia-uscu-prd-edge.azurewebsites.net` ||hostname === `alas-uscu-prd-alasedge.azurewebsites.net` || hostname === `edge.alas.com`) {
    // PROD environment config
    account = `alasdiauscuprd`;
    appService = `alasdia-uscu-prd-edge`;
    apiBaseURL = `https://${appService}.azurewebsites.net`; 
    imageBaseURL = `https://${account}.blob.core.windows.net`; 
    environment = Environment.PROD;
    alasEdgeAssetsAccess = `?sp=r&st=2021-01-26T15:42:22Z&se=2121-01-27T15:42:00Z&sv=2019-12-12&sig=4NqYz3Dw2C7lV5V%2FakJuxLozyNXPq8ZlsZuXDwbQ2Ug%3D&tn=AlasEdgeAssets`;
    alasEdgeTOCAccess = `?sp=r&st=2021-01-26T15:42:54Z&se=2121-01-27T15:42:00Z&sv=2019-12-12&sig=HXO6qmngvnfohtYYlkdjNkXI1xBriScbiETJdZ%2BGX3I%3D&tn=AlasEdgeTOC`;
    alasEdgeArticlesAccess = `?sp=r&st=2021-02-16T21:15:12Z&se=2121-02-17T21:15:00Z&sv=2020-02-10&sig=k%2B6%2BJaKULeJ9CVAF5gvYdNxmyb6asdq%2BRMdEW6fO3f4%3D&tn=AlasEdgeArticles`;
} else if (!hostname) {
    // Unit Testing environment
} else {
    throw new Error(`Environment not implemented: ${hostname}`);
}

export const API = apiBaseURL + `/api`;
export const HOST_NAME = hostname;
export const CURRENT_ENVIRONMENT = environment;

// Enter your storage appService name and shared key
export const azureImagePath = imageBaseURL + `/alas-edge-images/`;
export const azureAccount = account;
export const assetsAccess = alasEdgeAssetsAccess;
export const tocAccess = alasEdgeTOCAccess;
export const articlesAccess = alasEdgeArticlesAccess;
export const subTopicContainer = `alas-edge-subtopics`;
export const topicContainer = `alas-edge-topics`;
export const topicTOC = `AlasEdgeTOC`;
export const topicAssets = `AlasEdgeAssets`;
export const articlesTable = `AlasEdgeArticles`;