import { convertFileNameToKey } from './stringHelpers';
import moment from 'moment';

export function keys<O extends object>(obj: O): Array<keyof O> {
    if (obj !== null && obj !== undefined){
        return Object.keys(obj) as Array<keyof O>;
    } else {
        return [];
    }
    
}

export function checkIfEmpty(obj:object ) {
    for (const key of keys(obj)) {
        if (obj[key] !== null && obj[key] !== `` && obj[key] !== undefined){
            return false;
        }   
    }
    return true;
}

export function randomizeArrayElements(array:Array<any> ): Array<any> {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
}

export function sortArrayElementsByPriority(array:Array<any> ): Array<any> {

    array.sort((a, b) => {

        if(a.priority !== -1 && b.priority !== -1){
            if (a.priority > b.priority) {
                return 1;
            } else if (a.priority < b.priority) {
                return -1;
            } else if (a.priority === b.priority) {
                // if the priority is the same then use the name to order
                if(a.blob || b.blob){
                    return convertFileNameToKey(a.blob.name).toLocaleUpperCase().trim().localeCompare(convertFileNameToKey(b.blob.name).toLocaleUpperCase().trim());
                }else{
                    return 0;
                }

            } else {
                return 0;
            }    
        }else{
            if(a.blob || b.blob){
                return convertFileNameToKey(a.blob.name).toLocaleUpperCase().trim().localeCompare(convertFileNameToKey(b.blob.name).toLocaleUpperCase().trim());
            }else{
                return 0;
            }
        }
        
    });
    return array;
}

export function getLatestTimestamp(array:Array<any | undefined> ): string {
    let dates = array.filter(time => time !== undefined).map(d => moment(d));
    let maxDate = dates.length ? moment.max(dates) : undefined;
    return maxDate ? maxDate.format(`lll`) : ``;
}

export function convertToDate(updatedTimestamp:string | undefined): string {
    var date = moment(updatedTimestamp);
    return date.isValid() ? moment(updatedTimestamp).format(`ll`) : ``;
}
