import React, { useEffect, useRef, useState, ReactNode } from 'react';
import styled from "styled-components";
import { MdAddBox,MdFiberManualRecord, MdAddCircle, MdClose } from "react-icons/md"; 

interface SideNavContainerProps {
  children: ReactNode;
  subTopicName:string;
}

interface SideNavProps {
    padding?:string;
    displayFlex?: boolean;
    filled?: boolean;
}

interface SideItemProps {
  paddCol?:boolean;
  topicTitle?:boolean;
  otherTitle?:boolean;
  className?:string;
}

export const SideNav = styled.ul<SideNavProps>`
list-style: none;
margin: 140px 0 0;
padding:0;
padding-right: ${({ theme: { padding } }) => padding.small};
  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
    padding-right:0;margin: 15px 0px;padding: 20px 20px;
  }
`;

export const SideNavItem = styled.li<SideItemProps>`
    padding: 1px 0 0 16px;
    border-left: 6px solid ${({ theme: { colors } }) => colors.greys.xxlight};
    margin: 6px 0;
    &.isVisible{
      color: ${({ theme: { colors } }) => colors.primary.main};
      border-left: 6px solid ${({ theme: { colors } }) => colors.primary.main};
      @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
        border-left: 6px solid ${({ theme: { colors } }) => colors.greys.xxlight};
      }
    }

    ${({ topicTitle }) => topicTitle && 
    ` padding:0; & button{font-size: 16px;cursor:default;}`
    };
    ${({ otherTitle }) => otherTitle && 
    ` padding:0; & button{font-size: 16px;cursor:pointer;color: #6c717d;}`
    };

    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 

      ${({ topicTitle }) => topicTitle && 
      `width:100%;display:block;`
      };
    }

`;

export const SideNavLink = styled.button<SideItemProps>`
background: transparent; text-align:left;
border: none;
cursor: pointer;color: ${({ theme: { colors } }) => colors.greys.xdark};
font-size: 14px;.add-icon{display:inline-block;}
.visible-icon{display:none;}
  &.isVisible{color: ${({ theme: { colors } }) => colors.primary.main};
    outline: none;
    .add-icon{display:none;}
    .visible-icon{display:inline-block;}
  }
  ${({ otherTitle }) => otherTitle && 
  `width:100%;padding:0 5px;`
  };
`;

export const MobileMenuIcon = styled.button`
position: absolute;z-index: 11;right: 0;display:none;margin-top: 30px;
background: ${({ theme: { colors } }) => colors.white};
  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} {cursor:pointer; 
    display:block;border:none;line-height: 10px;box-shadow:0px 0px 10px rgba(0,0,0,0.2);
    padding:2px 20px 2px 3px; 
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}`;

export const AddBoxIcon = styled(MdAddBox)`
  font-size: 17px;
  color:${({ theme: { colors } }) => colors.greys.medium};position: absolute;
  margin: 0 0 0 4px;
`;

export const VisibleIcon = styled(MdFiberManualRecord)`
  font-size: 17px;
  color:${({ theme: { colors } }) => colors.primary.main};position: absolute;
  margin: 0 0 0 4px;
`;

export const OpenMenuIcon = styled(MdAddCircle)`
  font-size: 40px;
  color:${({ theme: { colors } }) => colors.primary.main};margin:0;
`;

export const CloseMenuIcon = styled(MdClose)`
  font-size: 40px;display:none;
  color:${({ theme: { colors } }) => colors.greys.medium};margin:0;
`;

export const SideNavContainerDiv = styled.div`
margin: 0;padding:0;

  @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} {
    ${SideNav}{
      display:none;
    }
    &.isMenuOpen{
      height: 100%;width: 100%;position: fixed;top: 0;bottom: 0; background: rgba(220,220,220,0.5);z-index: 10;padding:50px 0 0;
      ${SideNav}{ display:block;padding: 40px 20px 20px 1px;
        background: ${({ theme: { colors } }) => colors.white};position: fixed;z-index: 10;
        width: 80%;height: 100%;right: 0;
        div{background:transparent;}
        li{padding:0px 0 0 17px;border-left: 6px solid transparent;}
        li.subTopicLi{padding:0px 0 0 30px;}
        li.isVisible{border-left:6px solid ${({ theme: { colors } }) => colors.primary.main};}
        button.otherTopicButton{padding:10px 0 10px 20px; width:100%;}
      }
      ${MobileMenuIcon}{
        box-shadow:none;
        ${OpenMenuIcon}{display:none;}
        ${CloseMenuIcon}{display:block;}
      }
    }
    &.isSticky{
      ${MobileMenuIcon}{
        position: fixed; 
      }
    }  
        
  }
`;

export const SideNavContainer = (props: SideNavContainerProps) => {
  const [mobileMenuOpen, menuToggleState] = useState(false);
  const [isSticky, menuStickyState] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  let prevSubTopic = usePrevious(props.subTopicName);
  
  const toggleMobileMenu = () => {
    menuToggleState(!mobileMenuOpen);
  };

  const handlePageScroll = () => {
    if (containerRef.current) {
      menuStickyState(containerRef.current.getBoundingClientRect().top <= 0);
    }
  };

  // Used for getting Previous SubTopicName to be used for comparison 
  function usePrevious (subTopicName:any) {
    const ref = useRef();
    useEffect(() => {
      if(subTopicName){
        ref.current = subTopicName;
      }
    });
    return ref.current;
  };

  useEffect(() => {
    window.addEventListener(`scroll`, handlePageScroll);
    // If the prevoius subTopicName and new incoming subTopicName are not the same then close menu.
    if((props.subTopicName !== prevSubTopic && props.subTopicName !== ``)  || (prevSubTopic === undefined)){
      // close menu
      menuToggleState(false);
    }
    return () => {
      window.removeEventListener(`scroll`, () => handlePageScroll);
    };
  }, [props.subTopicName,prevSubTopic]);

  return (
      <SideNavContainerDiv className={`${mobileMenuOpen ? ` isMenuOpen` : ``} ${isSticky ? ` isSticky` : ``}`}  ref={containerRef}>
        <MobileMenuIcon onClick={toggleMobileMenu}>
          <OpenMenuIcon/><CloseMenuIcon/>
        </MobileMenuIcon>
          {props.children}
      </SideNavContainerDiv>
  );
};
