import * as React from 'react';
import * as AzureStorageBlob from "@azure/storage-blob";
import moment from 'moment';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { Layout } from '../layout/Layout';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { State } from '../../store/rootReducer';
import { bindActionCreators } from 'redux';
import Dashboard from './pages/DashboardPage';
import TopicPage from './pages/TopicPage';
import { azureImagePath } from '../../common/constants/config';
import { upsertSubTopics } from '../../store/actions/subTopicActions';
import { upsertTopics } from '../../store/actions/topicActions';
import { upsertArticles } from '../../store/actions/articlesActions';
import { upsertErrors } from '../../store/actions/errorActions';
import { upsertSearchOptions } from '../../store/actions/searchDataActions';
import { Topic, TopicMetaData } from '../../models/Topic';
import { SubTopic, SubTopicMetaData } from '../../models/SubTopic';
import { Article } from '../../models/Article';
import { SearchOptionGroup } from "../../models/SearchOptions";
import { convertFileNameToKey, convertFileName } from '../../common/helpers/stringHelpers';
import { handleApiErrors } from '../../common/helpers/errorHelpers';
import { getSearchOptions, requiredDataForPhraseSearch } from '../../common/helpers/searchHelpers';
import { getTopics, getTopicContent, getSubTopics, getSubTopicMetaData, getSubTopicContent, getTopicMetaData } from '../../api/StorageBlobApi';
import { getTopicSubTopicMap, getTopicAssetsMap, getArticlesMap } from '../../api/StorageTableApi';
import SearchResultsPage from './pages/SearchResultsPage';
import ErrorPage from './pages/ErrorPage';
import { SearchDataType } from '../../models/enums/SearchDataType';
import { updateLoadedSearchData } from '../../store/actions/searchDataActions';
import { AuthenticatedUser, convertStringDict } from '../../models/AuthenticatedUser';
import { myMSALObj, loginRequest, tokenRequest, authorities } from '../../common/authConfig';
import { AuthResponse } from 'msal';
import { setCurrentUserAction } from '../../store/actions/userActions';
import { CustomError } from "../../models/Error";
import { setAuthenticatedUserForTelemetry } from '../../common/helpers/TelemetryService';

export interface IAppStoreProps {
    newlyLoadedSearchDataTypes: Array<SearchDataType>;
    subTopics: Array<SubTopic>;
    topics: Array<Topic>;
    articles: Array<Article>;
    searchOptions: Array<SearchOptionGroup>;
    currentlyAuthenticatedUser: AuthenticatedUser;
    siteError: CustomError;
}

interface IAppStoreDispatchProps {
    updateLoadedSearchData: (newlyLoadedSearchDataTypes: Array<SearchDataType>) => void;
    upsertSubTopics: (subTopics: Array<SubTopic>) => void;
    upsertTopics: (topics: Array<Topic>) => void;
    upsertArticles: (articles: Array<Article>) => void;
    upsertSearchOptions: (options: Array<SearchOptionGroup>) => void;
    setCurrentUser: (authUser: AuthenticatedUser) => void;
    upsertErrors: (error: CustomError) => void;
    
}

type IAppProps = IAppStoreProps & IAppStoreDispatchProps;

interface IAppState {
    isLoggedIn: boolean;
}

interface IRoute {
    path: string;
    component: any;
    fetchInitialData: () => any;
}

const routes: Array<IRoute> = [
    {
        path: `/`,
        component: Dashboard,
        fetchInitialData: () => ({})
    },
    {
        path: `/Topic`,
        component: TopicPage,
        fetchInitialData: () => ({})
    },
    {
        path: `/SearchResults`,
        component: SearchResultsPage,
        fetchInitialData: () => ({})
    },
    {
        path: `/Error`,
        component: ErrorPage,
        fetchInitialData: () => ({})
    },
];
 
class App extends React.Component<RouteComponentProps<any> & IAppProps, IAppState> {

    public constructor(props: any ) {
        super(props);
        this.state = {
            isLoggedIn: false
        };
    }

    hasMounted: boolean = false;

    //#region LifeCycle Methods
    componentDidMount() {
        myMSALObj.handleRedirectCallback(this.authCallback);
        if (myMSALObj.getAccount() === null) {                
            myMSALObj.loginRedirect(loginRequest);
        } else { 
            if (!this.props.currentlyAuthenticatedUser.id) {
                
                var user = convertStringDict(myMSALObj.getAccount().idToken);
                this.props.setCurrentUser(user);
            }
            this.setState({
                isLoggedIn: true
            });
            myMSALObj.acquireTokenSilent(tokenRequest)
                .then((tokenResponse: AuthResponse) => {
                    setAuthenticatedUserForTelemetry(this.props.currentlyAuthenticatedUser);
                })
                .catch((error: any) => {
                    // fallback to interaction when silent call fails
                    return myMSALObj.acquireTokenRedirect(tokenRequest);
                });
        }
        this.hasMounted = true; 
        this.initialize();
    }

    componentDidUpdate(prevProps: IAppProps) {
        // If we have all the search data needed do the following
        if ( (prevProps.newlyLoadedSearchDataTypes !== this.props.newlyLoadedSearchDataTypes) && 
            requiredDataForPhraseSearch.every(rd => this.props.newlyLoadedSearchDataTypes.includes(rd)) && 
            this.props.searchOptions.length === 0 ) {
             // upsert search options
            this.props.upsertSearchOptions(getSearchOptions(this.props.topics, this.props.subTopics, this.props.newlyLoadedSearchDataTypes));
        }
    
    }

    componentWillUnmount() {
        this.hasMounted = false;
    }
    //#endregion

    render () {
        if (!this.state.isLoggedIn) {
            return(
                <div>Log in first.</div>
            );
        }
        if (this.state.isLoggedIn && !this.props.currentlyAuthenticatedUser.isAuthorized()){
            return(
                <div>Not authorized for ALAS Edge access. </div>
            );
        }
        return (
            <Layout>
                {routes.map(({ path, component: C, fetchInitialData }, index) => {
                    switch (path) {
                        case `/`:
                            return <Route exact path={path} key={index} render={(props) => <C {...props} fetchInitialData={fetchInitialData} />}  />;                                
                        default:
                            return <Route path={path} key={index} render={(props) => <C {...props} fetchInitialData={fetchInitialData} />} />;
                    }
                })}
            </Layout>
        );
    }

    //#region Fetch Data
    private initialize = (): void => {
        
        // fetch all topic blobs 
        getTopics().then(topicResponse => {
            if (this.hasMounted) {  
                let blobsFound: Array<AzureStorageBlob.BlobItem> = topicResponse;
                if (blobsFound.length) {
                    let originalTopics: Array<Topic> = [...this.props.topics];
                    blobsFound.forEach(bf => {
                        let topicIndex = originalTopics.findIndex(t => t.blob.name === bf.name);                        
                        if (topicIndex > -1) {                            
                            originalTopics.splice(topicIndex, 1, new Topic(bf));
                        } else {
                            originalTopics.push(new Topic(bf));
                        }
                    });       
                    // Update App State with recently aquired topic blobs
                    this.props.upsertTopics(originalTopics);
                    this.props.updateLoadedSearchData([SearchDataType.Topics]);

                    getTopicAssetsMap().then(mapResponse => {
                        if (this.hasMounted) {
                            // Get all of the Topic pairs available in the table
                            let responseValues: Array<any> = mapResponse.data.value;
                            // Create a list of all of the unique topic names and Tags
                            let topicKeys: Array<string> = responseValues.map(r => convertFileNameToKey(r.PartitionKey));
                            let uniqueTopicKeys: Array<string> = [...Array.from(new Set(topicKeys))];
                            let tagValueMap: Map<string, Array<string>> = new Map();
                            let priorityMap: Map<string, number | -1> = new Map();
                            let featuredMap: Map<string, boolean | false> = new Map();
                            let summaryMap: Map<string, string | ``> = new Map();
                            let updatedTimeMap: Map<string, string | ``> = new Map();
                            let accessMap: Map<string, boolean | true> = new Map();

                            let imageSrcs: Array<string> = responseValues.map(r =>{
                               if (r.Thumbnail){
                                return r.Thumbnail;
                               }else {
                                   return ``;
                                }                
                            });

                            uniqueTopicKeys.forEach(k => {
                                tagValueMap.set(k, []);
                                priorityMap.set(k, -1);
                                featuredMap.set(k, false);
                                summaryMap.set(k, ``);
                                updatedTimeMap.set(k, ``);
                                accessMap.set(k, true);
                            });

                            // Add the tags to their corresponding topics
                            responseValues.forEach(r => {
                                let topic = convertFileNameToKey(r.PartitionKey);
                                let hasAccess = this.props.currentlyAuthenticatedUser.hasAccess(r.Roles);
                                let relatedTags = hasAccess ? tagValueMap.get(topic) : undefined;
                                let updatedTags = (relatedTags && relatedTags.length && hasAccess) ? [...relatedTags] : [];

                                updatedTags.push(r.Tags);
                                tagValueMap.set(topic, updatedTags);
                                priorityMap.set(topic, r.Priority);
                                featuredMap.set(topic, r.Featured);
                                summaryMap.set(topic, r.Summary);
                                updatedTimeMap.set(topic, r.Updated);
                                accessMap.set(topic, hasAccess);
                            });

                            // Sort, Split and Upsert Topics with tags, thumbnail and priority.
                            let topicsWithAssets: Array<Topic> = [...this.props.topics];
                            this.props.topics.forEach((t, index) => {
                                let updatedTopic: Topic = {...t};
                                let relatedTags: Array<string> | undefined = tagValueMap.get(convertFileNameToKey(t.blob.name));
                                let thumbnailImage = imageSrcs.find(image => convertFileNameToKey(image) === convertFileNameToKey(t.blob.name) + `-Thumbnail`);
                                let relatedPriority:boolean = priorityMap.has(convertFileNameToKey(t.blob.name));
                                let hasFeatured:boolean = featuredMap.has(convertFileNameToKey(t.blob.name));
                                let hasSummary:boolean = summaryMap.has(convertFileNameToKey(t.blob.name));
                                let hasUpdated:boolean = updatedTimeMap.has(convertFileNameToKey(t.blob.name));

                                if (accessMap.get(convertFileNameToKey(t.blob.name)) !== undefined) {
                                    let hasAccess = accessMap.get(convertFileNameToKey(t.blob.name));
                                    if (hasAccess !== undefined) {
                                        updatedTopic.hasAccess = hasAccess;
                                    }
                                }

                                if(relatedTags){
                                    let filteredTags: any = relatedTags.filter(tag => tag !== undefined);
                                    let tagArray: Array<string> | undefined = (filteredTags && filteredTags.length) ? filteredTags.toString().split(`,`) : [];
                                    updatedTopic.tagKeys = (tagArray && tagArray.length) ? tagArray.sort(function(textA, textB) {
                                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                    }) : [];
                                }

                                // Check The Image Array Thumbnail matches The t.blob.name                  
                                if(thumbnailImage){
                                    updatedTopic.thumbnail =  `${azureImagePath}${thumbnailImage}`;
                                }

                                // Check if there is a Topic title in the priority map with a matching key. if so assign a priority.
                                // If not leave as default 0
                                if(relatedPriority){                                       
                                    if(priorityMap.get(convertFileNameToKey(t.blob.name)) !== undefined){
                                        let priority = priorityMap.get(convertFileNameToKey(t.blob.name));
                                        if (priority !== undefined) {
                                            updatedTopic.priority = priority;
                                        }
                                    }
                                }
                                if(hasFeatured){                                       
                                    if(featuredMap.get(convertFileNameToKey(t.blob.name)) !== undefined){
                                        let featured = featuredMap.get(convertFileNameToKey(t.blob.name));
                                        if (featured !== undefined) {
                                            updatedTopic.featured = featured;
                                        }
                                    }
                                }
                                if(hasSummary){                                       
                                    if(summaryMap.get(convertFileNameToKey(t.blob.name)) !== undefined){
                                        let summary = summaryMap.get(convertFileNameToKey(t.blob.name));
                                        if (summary !== undefined) {
                                            updatedTopic.summary = summary;
                                        }
                                    }
                                }
                                if(hasUpdated){                                       
                                    if(updatedTimeMap.get(convertFileNameToKey(t.blob.name)) !== undefined){
                                        let timeStamp = updatedTimeMap.get(convertFileNameToKey(t.blob.name));
                                        if (timeStamp !== undefined) {
                                            updatedTopic.updatedTimestamp = timeStamp;
                                        }
                                    }
                                }

                                topicsWithAssets.splice(index, 1, updatedTopic);
                            });

                            this.props.upsertTopics(topicsWithAssets);
                            this.props.updateLoadedSearchData([SearchDataType.TopicTags]);
                            return topicsWithAssets;   
                        }
                    }).then(topicsWithAssets => {
                        // Determine which subtopics map to which topics & update the App State again once that data is available
                        getTopicSubTopicMap().then(mapResponseSub => {
                            if (this.hasMounted) {

                                // Get all of the topic/subtopic pairs available in the table
                                let responseValues: Array<any> = mapResponseSub.data.value; 

                                // Create a list of all of the unique topic names
                                let topicKeysSub: Array<string> = responseValues.map(r => convertFileNameToKey(r.PartitionKey));
                                let uniqueTopicKeysSub: Array<string> = [...Array.from(new Set(topicKeysSub))];

                                // Create a map of all topics, initially setting the corresponding sub-topics as an empty array
                                let subTopicValueMap: Map<string, Array<string>> = new Map();

                                uniqueTopicKeysSub.forEach(k => {
                                    subTopicValueMap.set(k, []);
                                });

                                // Add the sub-topics to their corresponding topics
                                responseValues.forEach(r => {
                                    let topic = convertFileNameToKey(r.PartitionKey);
                                    //Get check if topic and/or subtopic has access
                                    let topicData = topicsWithAssets?.find(t => (convertFileNameToKey(t.blob.name)) == topic);
                                    if (topicData !== undefined && topicData.hasAccess) {
                                        if (this.props.currentlyAuthenticatedUser.hasAccess(r.Roles)) {
                                            let relatedSubTopics = subTopicValueMap.get(topic);
                                            let updatedSubTopics = (relatedSubTopics && relatedSubTopics.length) ? [...relatedSubTopics] : [];
                                            updatedSubTopics.push(convertFileNameToKey(r.RowKey));
                                            subTopicValueMap.set(topic, updatedSubTopics);
                                        }
                                    }
                                });

                                // Add subTopicKeys to each topic with Any or Empty Tag Array                             
                                let topicsWithSubTopics: Array<Topic> = (topicsWithAssets && topicsWithAssets.length) ? [...topicsWithAssets] : [];
                                if(topicsWithAssets && topicsWithAssets.length){
                                    topicsWithAssets.forEach((t, index) => {
                                        let updatedTopic: Topic = {...t};
                                        let relatedSubTopics: Array<string> | undefined = t.hasAccess ? subTopicValueMap.get(convertFileNameToKey(t.blob.name)) : [];
                                        updatedTopic.subTopicKeys = (relatedSubTopics && relatedSubTopics.length) ? relatedSubTopics : [];
                                        topicsWithSubTopics.splice(index, 1, updatedTopic);
                                    });
                                }
                                
                                // Sort the subTopicKeys for each topic by their assigned priority
                                topicsWithSubTopics.forEach(topic => {
                                    topic.subTopicKeys.sort((subTopicKey1, subTopicKey2) => {
                                        // Find the relevant response data that should contain priority values (if any)
                                        let subTopicKey1Response: any = responseValues.find(rv => {
                                            return (convertFileNameToKey(rv.PartitionKey) === convertFileNameToKey(topic.blob.name) &&
                                                    (convertFileNameToKey(rv.RowKey) === subTopicKey1));
                                        });
                                        let subTopicKey2Response: any = responseValues.find(rv => {
                                            return (convertFileNameToKey(rv.PartitionKey) === convertFileNameToKey(topic.blob.name) &&
                                                    (convertFileNameToKey(rv.RowKey) === subTopicKey2));
                                        });

                                        // If the responses contain priorities, set them (setting all undesignated priorities to 9999 so as to send them to the bottom of the sort)
                                        let subTopicKey1Priority: number = subTopicKey1Response ? Number(subTopicKey1Response.Priority) : 9999;
                                        let subTopicKey2Priority = subTopicKey2Response.Priority ? Number(subTopicKey2Response.Priority) : 9999;

                                        // Pre-process the sort result
                                        let result = subTopicKey1Priority - subTopicKey2Priority;

                                        // If a tie exists, break it alphabetically
                                        if (result === 0) {
                                            result = subTopicKey1.toLocaleLowerCase().localeCompare(subTopicKey2.toLocaleLowerCase());
                                        }

                                        return result;
                                    });
                                });

                                this.props.upsertTopics(topicsWithSubTopics);
                                
                                this.props.updateLoadedSearchData([SearchDataType.TopicSubTopicKeys]); 

                                topicsWithSubTopics.forEach(t => {
                                    getTopicMetaData(t.blob.name).then(metaResponse => {
                                        if (this.hasMounted) {  
                                            let topicsWithMetaData: Array<Topic> = [...this.props.topics];
                                            let metaData: TopicMetaData = {
                                                lastModified: moment(metaResponse.headers[`last-modified`]),
                                                title: metaResponse.headers[`x-ms-meta-title`] ? metaResponse.headers[`x-ms-meta-title`] : convertFileName(t.blob.name),
                                            };                             
                                            let topicMetaDataIndex = topicsWithMetaData.findIndex(twmd => twmd.blob.name === t.blob.name);
                                            if (topicMetaDataIndex > -1) {
                                                let topicWithNewMetaData = {...topicsWithMetaData[topicMetaDataIndex]};
                                                topicWithNewMetaData.metadata = metaData;
                                                topicsWithMetaData.splice(topicMetaDataIndex, 1, topicWithNewMetaData);
                                                this.props.upsertTopics(topicsWithMetaData);  
                                                this.props.updateLoadedSearchData([SearchDataType.TopicMetaData]);                                      
                                            } 
                                            getTopicContent(t.blob.name).then(topicContentResponse => {
                                                if (this.hasMounted) {
                                                    let topicsWithContent = [...this.props.topics];
                                                    let indexToUpdate = topicsWithContent.findIndex(topic => topic.blob.name === t.blob.name);
                                                    if (indexToUpdate > -1) {
                                                        let topicWithNewContent = {...topicsWithContent[indexToUpdate]};
                                                        topicWithNewContent.htmlContent = topicContentResponse.data;
                                                        topicsWithContent.splice(indexToUpdate, 1, topicWithNewContent);                                                        
                                
                                                        this.props.upsertTopics(topicsWithContent);
  
                                                    }
                                                }
                                            }).catch((error) => { 
                                                !this.props.siteError.statusCode && (
                                                    this.props.upsertErrors(handleApiErrors(error, `Error getting Topic Content:`))
                                                ); 
                                            });
                                      
                                        }
                                    }).catch((error) => { 
                                        !this.props.siteError.statusCode && (
                                            this.props.upsertErrors(handleApiErrors(error, `Error getting Topic MetaData:`))
                                        );
                                        
                                    }); 
                                }); 
                  
                            }
                        }).catch((error) => { 
                            !this.props.siteError.statusCode && (
                                this.props.upsertErrors(handleApiErrors(error, `Error getting Subtopic Map:`))
                            );      
                        });
                    }).catch((error) => { 
                        !this.props.siteError.statusCode && (
                            this.props.upsertErrors(handleApiErrors(error, `Error getting Topic Assets:`))
                        );
                    });
                }
                
            }
        }).catch((error) => { 
            !this.props.siteError.statusCode && (
                this.props.upsertErrors(handleApiErrors(error, `Error getting Topics:`))
            );
        });
            
        // fetch all subtopic blobs 
        getSubTopics().then(subTopicResponse => {
            if (this.hasMounted) {  
                let stBlobsFound: Array<AzureStorageBlob.BlobItem> = subTopicResponse;
                if (stBlobsFound.length) {
                    let originalSubTopics: Array<SubTopic> = [...this.props.subTopics];
                    stBlobsFound.forEach(bf => {
                        let subTopicIndex = originalSubTopics.findIndex(st => st.blob.name === bf.name);                        
                        if (subTopicIndex > -1) {                            
                            originalSubTopics.splice(subTopicIndex, 1, new SubTopic(bf));
                        } else {
                            originalSubTopics.push(new SubTopic(bf));
                        }
                    });          
                
                    // Store the retreived subTopic blobs in the reducer for faster future access
                    this.props.upsertSubTopics(originalSubTopics);
                    this.props.updateLoadedSearchData([SearchDataType.SubTopics]);

                    originalSubTopics.forEach(subTopic => {
                        // Initiate logic to get the metaData & htmlContent affiliated with each blob
                        getSubTopicMetaData(subTopic.blob.name).then(metaResponse => {
                            if (this.hasMounted) {  
                                let subTopicsWithMetaData: Array<SubTopic> = [...this.props.subTopics];
                                let metaData: SubTopicMetaData = {
                                    lastModified: moment(metaResponse.headers[`last-modified`]),
                                    powerBIInstructions: metaResponse.headers[`x-ms-meta-powerbiinstructions`],
                                    powerBIPageName: metaResponse.headers[`x-ms-meta-powerbipageid`],
                                    powerBIReportId: metaResponse.headers[`x-ms-meta-powerbireportid`],
                                    title: metaResponse.headers[`x-ms-meta-title`] ? metaResponse.headers[`x-ms-meta-title`] : convertFileName(subTopic.blob.name),
                                };   
                                let subTopicMetaDataIndex = subTopicsWithMetaData.findIndex(st => st.blob.name === subTopic.blob.name);
                                if (subTopicMetaDataIndex > -1) {
                                    let subTopicWithNewMetaData = {...subTopicsWithMetaData[subTopicMetaDataIndex]};
                                    subTopicWithNewMetaData.metadata = metaData;
                                    subTopicsWithMetaData.splice(subTopicMetaDataIndex, 1, subTopicWithNewMetaData);
                                    this.props.upsertSubTopics(subTopicsWithMetaData);
                                    this.props.updateLoadedSearchData([SearchDataType.SubTopicMetaData]);                                        
                                } 

                                getSubTopicContent(subTopic.blob.name).then(contentResponse => {                       
                                    if (this.hasMounted) {  
                                        let subTopicsWithContent: Array<SubTopic> = [...this.props.subTopics];
                                        let subTopicContentIndex = subTopicsWithContent.findIndex(st => st.blob.name === subTopic.blob.name);
                                        if (subTopicContentIndex > -1) {
                                            let subTopicWithNewContent = {...subTopicsWithContent[subTopicContentIndex]};
                                            subTopicWithNewContent.htmlContent = contentResponse.data;
                                            subTopicsWithContent.splice(subTopicContentIndex, 1, subTopicWithNewContent);                                  
                                            this.props.upsertSubTopics(subTopicsWithContent);                    
                                        }
                                    }
                                }).catch((error) => { 
                                    !this.props.siteError.statusCode && (
                                        this.props.upsertErrors(handleApiErrors(error, `Error getting Subtopic Content:`))
                                    );
                                });  
                            }
                        }).catch((error) => { 
                            
                          !this.props.siteError.statusCode && (
                            this.props.upsertErrors(handleApiErrors(error, `Error getting Subtopics MetaData:`))
                          );
                            
                        });                    
                    });
                }
            }  
        }).catch((error) => { 
            !this.props.siteError.statusCode && (
                this.props.upsertErrors(handleApiErrors(error, `Error Getting Subtopics:`))
            );
            
        });;

        getArticlesMap().then(responseValues => {    
            
            let articlesResponse: Array<any> = responseValues.data.value;
            if (articlesResponse.length) {
                let newArticles: Array<Article> = [...this.props.articles];
                articlesResponse.forEach(article => {
                    let topicIndex = newArticles.findIndex(t => t.key === article.PartitionKey);  
                    let newArticle:Article = {
                        key: article.PartitionKey,
                        title: article.Title,
                        priority: article.Priority,
                        urlLink: article.Link,
                        thumbnail: article.Thumbnail
                    };
                    
                    if (topicIndex > -1) {                            
                        newArticles.splice(topicIndex, 1, newArticle);
                    } else {
                        newArticles.push(newArticle);
                    }

                });                 
                this.props.upsertArticles(newArticles);
            }

        }).catch((error) => { 
            !this.props.siteError.statusCode && (
                this.props.upsertErrors(handleApiErrors(error, `Error getting Articles:`))
            );      
        });

    }
    //#endregion

    public authCallback = (error: any, success: AuthResponse|undefined) => {
        if (error) {
            
            if (error.errorMessage.indexOf(`AADB2C90118`) > -1) {
                try {
                    // Password reset policy/authority
                    myMSALObj.loginRedirect(authorities.forgotPassword);
                } catch(err) {
                }
            }
        } else if (success) {
            // Login
            if (success.tokenType === `id_token`){
                if (!this.props.currentlyAuthenticatedUser.id) {
                
                    var user = convertStringDict(myMSALObj.getAccount().idToken);
                    this.props.setCurrentUser(user);
                }
            }
            // API 
            else if (success.tokenType === `access_token`) {
            }
        }
    }
}

function mapStateToProps(state: State) {
    return {
        currentlyAuthenticatedUser: state.currentlyAuthenticatedUser,
        newlyLoadedSearchDataTypes: state.loadedSearchData,
        subTopics: state.allSubTopics,
        topics: state.allTopics,
        articles:state.allArticles,
        searchOptions: state.allSearchOptions,
        siteError: state.siteError
    };
}
  
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        setCurrentUser: setCurrentUserAction,
        updateLoadedSearchData: updateLoadedSearchData,
        upsertSubTopics: upsertSubTopics,
        upsertTopics: upsertTopics,
        upsertArticles: upsertArticles,
        upsertSearchOptions: upsertSearchOptions,
        upsertErrors: upsertErrors,
    },        
    dispatch);  
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));