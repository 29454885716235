//#region Loading SubTopics
export const SET_POWERBI = `SET_POWERBI`;
export const UPSERT_SUBTOPICS = `UPSERT_SUBTOPICS`;
export const UPSERT_TOPICS = `UPSERT_TOPICS`;
export const UPSERT_ARTICLES = `UPSERT_ARTICLES`;
export const UPSERT_SEARCHOPTIONS = `UPSERT_SEARCHOPTIONS`;
export const UPDATE_LOADEDSEARCHDATA = `UPDATE_LOADEDSEARCHDATA`;
export const UPDATE_ERRORS = `UPDATE_ERRORS`;
//#endregion

//#region User Actions
export const CURRENT_USER_SET = `CURRENT_USER_SET`;
//#endregion
