import  React from "react";
import { ReactNode } from "react";
import { ThemeProvider } from 'styled-components';

interface IProps {
    children: ReactNode;
}

// Default Theme
const defaultTheme = {
  colors: {
    powderWhite: `#FFFDF9`,
    white: `#FFFFFF`,
    onyx: `#36313D`,
    darkGrey: `#333333`,
    primary: {
      main: `#00aeef`,
      light: `#1f1230`,
      xlight: `#825dc7`
    },
    secondary: {
      alert: `#2ea3f2`,
      default: `#535b7c`
    },
    //
    greys: {
      dark: `#5b606e`,
      xdark: `#3c404a`, 
      medium: `#b9b9b9`,
      xmedium: `#f0f2f7`,
      xxmedium: `#5d6770`,
      light: `#babfce`,
      xlight: `#d0d0d0`,
      xxlight: `#f7f7f7`
    }
  },
  padding: {
    large: `40px`,
    medium: `30px`,
    small: `20px`
  },
  fonts: {
    body: `museo_sans300`,
    title: `bembo`,
    alt: `foundrysterling-bookregular`
  },
  fontSizes: {
    smallest: `.7em`,
    small: `.9em`,
    medium: `2em`,
    large: `3em`,
    titleSmall: `1.2em`,
    titleLarger: `1.9em`
  },
  deviceSizes: {
    handheldOnly: `only screen and (min-device-width : 300px) and (max-device-width : 768px)`,
    mobile: `(min-width: 320px)`,
    mobileL: `(min-width: 425px)`,
    mobileXL: `(min-width: 576px)`,
    tablet: `(min-width: 768px)`,
    laptop: `(min-width: 1024px)`,
    laptopL: `(min-width: 1440px)`,
    desktop: `(min-width: 2560px)`,
    print: `print`
  }
};
 
const Theme = ({ children, ...props }: IProps) => (
  <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
);

export default Theme;
