import { StringDict } from "msal/lib-commonjs/MsalTypes";

export class AuthenticatedUser  {
    constructor(public id: string = ``,         
                public name: string = ``,
                public email: string = ``,
                public originalUserId: string = ``,
                public userRoles: string[] = [],
                public securityGroup: string = ``,
                public firmNumber: string = ``,
                public impersonatedFirmId: string = ``) {
    }

    initials = () => {
        if (this.name) {
            return `${this.name.substring(0, 1)}${this.lastName().substring(0, 1)}`;
        } else {
            return ``;
        }        
    }

    lastName = (): string => {
        if (this.name) {    
            let name = this.name.split(` ` );
            return name[name.length - 1];
        } else {
            return ``;
        }
    }

    isALAS = (): boolean => {
        return this.email.endsWith(`@alas.com`);
    }

    isAuthorized = (): boolean => {
        return this.userRoles.findIndex(r => r.toUpperCase() === `ADM1`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `ALAS STAFF`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `BOARD MEMBER`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `CA`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `CHAIR`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `CLM`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `FRM1`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `FRM2`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `GC`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `GC2`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `LPM`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `LPP1`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `LPP2`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `LPRC`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `MCM`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `MGR`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `IS`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `EWA`) !== -1
            || this.userRoles.findIndex(r => r.toUpperCase() === `NONMEMBER EWA`) !== -1;
    }

    hasAccess = (role: string): boolean => {        
        if (!role || this.isALAS()) {
            return true;
        }
        var roles = role.split(',').filter(Boolean);
        var result = false;
        for (let i = 0; i < roles.length; i++) {
            if (this.userRoles.indexOf(roles[i]) !== -1) {
                result = true;
                break;
            }
        }
        return result;
    }
}

export const convertStringDict = (idToken: StringDict): AuthenticatedUser => {
    const roles: string[] = [ ...idToken.userRoles ];
    console.log('IDENTITY TOKEN:')
    console.log(JSON.stringify(idToken))
    return new AuthenticatedUser(
        idToken.sub,
        idToken.name,
        idToken.email,
        idToken.originalUserId,
        roles,
        idToken.securityGroup,
        idToken.firmNumber,
        ``
    );
};