import { Topic } from "../../models/Topic";
import { Reducer } from "redux";
import { UPSERT_TOPICS } from "../actions/actionTypes";

export const topicReducer: Reducer<Array<Topic>> = (state = [], action) => {
    switch (action.type) {
        case UPSERT_TOPICS:
            let newTopics: Array<Topic> = action.payload;
            let newState: Array<Topic> = [...state];
            newTopics.forEach(newTopic => {
                
                // Ensure subTopics are NEVER saved to app state as visible (to guarantee user will always have to manually trigger individual subTopics to become visible within a given topic)
                let newHiddenTopic = {...newTopic};
                newHiddenTopic.visible = false;

                let originalTopicIndex = state.findIndex(topic => {
                    return topic.blob.name === newTopic.blob.name;
                });
                if (originalTopicIndex > -1) {
                    newState.splice(originalTopicIndex, 1, newHiddenTopic);
                } else {
                    newState.push(newHiddenTopic);
                }
            }); 
            return newState;
        default:
            return state;
    }
};