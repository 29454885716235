import { SearchDataType } from "../../models/enums/SearchDataType";
import { SearchOptionGroup } from "../../models/SearchOptions";
import { UPDATE_LOADEDSEARCHDATA, UPSERT_SEARCHOPTIONS } from "./actionTypes";

export const updateLoadedSearchData = (newlyLoadedSearchDataTypes: Array<SearchDataType>) => {
    return {
        type: UPDATE_LOADEDSEARCHDATA,
        payload: newlyLoadedSearchDataTypes
    };
};

export const upsertSearchOptions = (searchOptions: Array<SearchOptionGroup>) => {
    return {
        type: UPSERT_SEARCHOPTIONS,
        payload: searchOptions
    };
};