import { Reducer } from "redux";
import { UPSERT_ARTICLES } from "../actions/actionTypes";
import { Article } from "../../models/Article";
 
export const articleReducer: Reducer<Array<Article>> = (state = [], action) => {
    switch (action.type) {
        case UPSERT_ARTICLES:
            let newData: Array<Article> = action.payload;
            let newState: Array<Article> = [...state];
            newData.forEach(newDataType => {         
                if (!newState.some(dataType => dataType === newDataType)) {
                    newState.push(newDataType);
                }
            }); 
            return newState;
            
        default:
            return state;
    }
};