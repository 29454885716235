import { convertFileNameToKey, getTitleText } from "../common/helpers/stringHelpers"
import { SubTopic } from "./SubTopic";
import { Topic } from "./Topic";
import moment from 'moment';

export class SearchResult {
    public static fromTopic = (topic: Topic, subTopic?: SubTopic, relevance = 1) => {
        let title = subTopic ? `${getTitleText(topic)} > ${getTitleText(subTopic)}` : getTitleText(topic);
        let subTopicUrlSuffix = subTopic ? `&subtopic=${convertFileNameToKey(subTopic.blob.name)}`: ``;
        let lastModified = moment();
        if (subTopic && subTopic.metadata) {
            lastModified = subTopic.metadata.lastModified
        } else if (topic && topic.metadata) {
            lastModified = topic.metadata.lastModified
        }

        return new SearchResult(
            title,
            `/Topic?title=${convertFileNameToKey(topic.blob.name)}${subTopicUrlSuffix}`,            
            lastModified,
            topic.tagKeys,
            relevance,
        )
    }

    constructor(
        public title: string,
        public url: string,
        public lastModified: moment.Moment,
        public tags: Array<string> = [],
        public relevance: number = 1,
    ) {}
}