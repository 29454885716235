 
import * as React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Middleware, applyMiddleware, createStore } from 'redux';
import AppRouter from './AppRouter';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import rootReducer from './store/rootReducer';
import './normalize.css';

const history = createBrowserHistory();
const rootElement = document.getElementById(`root`);

// setup middleware for intercepting actions(router, logging, signalr etc)
const middlewares: Middleware[] = [];
middlewares.push(routerMiddleware(history));

if (process.env.NODE_ENV === `development`) {
    const { createLogger } = require(`redux-logger`);
  
    const logger = createLogger({
        collapsed:true
    });
  
    middlewares.push(logger);
}

const store = createStore(
    rootReducer(history),
    applyMiddleware(...middlewares)
);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AppRouter/>
        </ConnectedRouter>
    </Provider>,
    rootElement
);

unregisterServiceWorker();

