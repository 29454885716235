import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { State } from "../../../store/rootReducer";
import {Row,Col, Padding} from "../../../common/helpers/styling/ResponsiveGrid";
import { H1Error, H1} from "../../../common/helpers/styling/Typography";
import { getAppInsights } from '../../../common/helpers/TelemetryService';
import { CustomError } from "../../../models/Error";

//#region Props & State
interface IErrorPageStoreProps {
    siteError: CustomError; 
}

interface IErrorPageState {
}
 
class ErrorPage extends React.Component<RouteComponentProps<any> & IErrorPageStoreProps, IErrorPageState> {    
    hasMounted: boolean = false;
    constructor(props: any) {
        super(props);

        // Set Default States
        this.state = {};

        getAppInsights().trackPageView();
    }

    //#region LifeCycle Methods
    componentDidMount() {}

    componentWillUnmount() {}
    //#endregion

    render () {
        if (this.props.siteError.errorTitle === ``) {
            return <Redirect to='/'/>;
        }
        return (<>
                <Row style={{alignContent:`start`, flexGrow: 3 }} grow filled reverseOrderMobile>
                    <Col xs={12} md={2} lg={2} hideTablet hideMobile style={{paddingTop: 110 }} hideOnPrint></Col>
                    <Col pr={12} xs={12} md={9} lg={8} grow filled style={{minHeight: `450px`}}>
                        <Padding large>
                            <Row style={{alignContent:`start`,justifyContent:`flex-start`, flexGrow: 3 }} grow>
                                <H1Error>
                                <svg style={{width: `100px`,height: `100px`}} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" fill="#b9b9b9"/></svg>                         
                                </H1Error>
                                <H1 style={{textAlign:`center`, width:`100%`}}>Site is down due to maintenance. Please try back later
                                </H1>

                            </Row>
                        </Padding>
                    </Col>
                    <Col xs={12} md={3} lg={2} style={{paddingTop: 50 }} hideOnPrint></Col>  
                </Row>
            </>);
    }
 
}

function mapStateToProps(state: State) {
    return {
        siteError: state.siteError
    };
}
  
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({},dispatch);  
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
