import * as React from 'react';
import NavMenu from './NavMenu';
import { Footer } from './Footer';
import styled from 'styled-components';

//#region Styled Components
const BodyContainer = styled.div`
display: flex;
flex-direction: column;
flex-grow: 3;
height: 100%;  
background: ${({ theme: { colors } }) => colors.greys.xxlight};
`;
//#endregion

export class Layout extends React.Component {
    render () {
        return (
            <BodyContainer>
                <NavMenu />
                {this.props.children}
                <Footer />
            </BodyContainer>
        );
    }
}
