import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { SubTopic } from '../models/SubTopic';
import { subTopicReducer } from './reducers/subTopicReducer';
import { Topic } from '../models/Topic';
import { Article } from '../models/Article';
import { SearchOptionGroup } from '../models/SearchOptions';
import { topicReducer } from './reducers/topicReducer';
import { errorReducer } from './reducers/errorReducer';
import { SearchDataType } from '../models/enums/SearchDataType';
import { searchDataReducer, searchOptionsReducer } from './reducers/searchDataReducer';
import { AuthenticatedUser } from '../models/AuthenticatedUser';
import { userReducer } from './reducers/userReducer';
import { articleReducer } from './reducers/articleReducer';
import { CustomError } from "../models/Error";

export interface State {
    allSubTopics: Array<SubTopic>;
    allTopics: Array<Topic>;
    allArticles: Array<Article>;
    allSearchOptions: Array<SearchOptionGroup>;
    currentlyAuthenticatedUser: AuthenticatedUser,
    loadedSearchData: Array<SearchDataType>;
    siteError: CustomError;
}

export default (history: any) => combineReducers({
    allSubTopics: subTopicReducer,
    allTopics: topicReducer,
    allArticles: articleReducer,
    allSearchOptions: searchOptionsReducer,
    currentlyAuthenticatedUser: userReducer,
    loadedSearchData: searchDataReducer,
    siteError: errorReducer,
    router: connectRouter(history),
});