import styled from "styled-components";
 
interface TextProps {
    type?: string;
    id?: string; 
    placeholder?: string;  
}

interface TypeProps {
    noBorder?: boolean;
    mainTitle?: boolean;
}

interface RadioProps {
    type?: string;
    id?: string; 
    name?: string;
    value?:string  
}
 
export const H1 = styled.h1`
    padding:20px 0; margin:0;
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';font-weight: 300;
    
`;
export const H2 = styled.h2<TypeProps>`
font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
    font-weight: 400;font-size: 20px; border-bottom:1px solid ${({ theme: { colors } }) => colors.greys.xdark};
    padding: 0 0 20px;  color: ${({ theme: { colors } }) => colors.greys.medium};font-weight: 300;

    ${({ mainTitle }) => mainTitle ? `padding: 0 0 10px;`: `` };
 
    color: ${({ mainTitle, theme: { colors } }) => mainTitle ? colors.greys.xdark : colors.greys.medium};

    ${({ noBorder }) => noBorder ? `border:none;padding: 0;margin:10px 0; & a{ color:inherit;text-decoration: none;}`: `` };
    
`;

export const H2Search = styled.h2<TypeProps>`
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
    font-weight:600;font-size: 20px;  
    padding: 0 0 10px;   
    color: ${({ theme: { colors } }) => colors.primary.main};
 
`;

export const H2Status = styled.h2`
font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
    font-weight: 400;font-size: 20px; 
    border-top:1px solid ${({ theme: { colors } }) => colors.greys.xdark};
 
    font-size: 26px;

    line-height:36px;
    padding: 20px 5% 20px;
    margin: 0 60px;
    color: ${({ theme: { colors } }) => colors.greys.xdark};
text-align:center;
    



`;
export const H3 = styled.h3`
font-family:${({ theme: { fonts } }) => fonts.body}, 'serif'; text-transform: uppercase;
letter-spacing: 1px; font-size:11px;padding: 0 14px;
     
`;
export const H4 = styled.h4`
    padding: 30px 40px;
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
`;

export const H4Time = styled.h4`
    padding:0px 40px;text-align:center; margin-top: 0;
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
`;

export const H1Error = styled.h1`
    padding:0px 40px;text-align:center; margin-top: 0; width: 100%; margin-bottom:0;
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';  font-size: 140px;text-align: center;
    line-height: 110px;   color: ${({ theme: { colors } }) => colors.primary.main}; 
`;

export const H5 = styled.h5`
font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0;
    color: ${({ theme: { colors } }) => colors.greys.medium}; 
`;

export const H6 = styled.h6`
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 8px 0;
    color: ${({ theme: { colors } }) => colors.greys.dark}; 
`;