import styled from "styled-components";

interface UlProps {
    padding?:string;
    displayFlex?: boolean;
    filled?: boolean;
}

interface LiProps {
  paddCol?:boolean;
  // any other props that come into the component
}
  
export const Ul = styled.ul<UlProps>`
margin: 0;

padding: 0px;

list-style: none;
 

`;

export const Li = styled.li<LiProps>`
border-bottom: 1px solid #ccc;
outline: 0;
padding:${({ theme: { padding } }) => padding.small} 0; margin:0;text-decoration: none;position:relative;
font-size: 14px;
line-height: 24px;
 

`;
 