import * as React from 'react';
import * as pbi from 'powerbi-client';
import * as queryString from 'query-string';
import { State } from '../../../store/rootReducer';
import { connect } from 'react-redux';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom';
import { ParsedQuery } from 'query-string';
import { SubTopic } from '../../../models/SubTopic';
import { bindActionCreators } from 'redux';
import { convertFileNameToKey, getTitleUrl, getTitleText } from '../../../common/helpers/stringHelpers';
import { Topic } from '../../../models/Topic';
import { getReport } from '../../../api/PowerBiEmbedApi';
import { IEmbedConfiguration } from 'embed';
import {Row,Col, Padding} from "../../../common/helpers/styling/ResponsiveGrid";
import {H1, H3, H6, H5, H4Time} from "../../../common/helpers/styling/Typography";
import {SideNavContainer, SideNav, SideNavItem, SideNavLink, AddBoxIcon, VisibleIcon } from "../../../common/helpers/styling/SideNav";
import styled from 'styled-components';
import { TagContainer, TagItem, TagLink } from '../../../common/helpers/styling/Tags';
import { randomizeArrayElements, sortArrayElementsByPriority, convertToDate } from '../../../common/helpers/generalHelpers';
import { SearchDataType } from '../../../models/enums/SearchDataType';
import { Sticky } from '../../../common/helpers/styling/Sticky';
import { SubLogo } from '../../shared/Logo';
import { InputText } from '../../../common/helpers/styling/Inputs';
import { FlexList, FlexListItem } from '../../../common/helpers/styling/FlexHelper';
import { MdArrowBack } from "react-icons/md"; 
import { SearchOptionGroup } from "../../../models/SearchOptions";
import { myMSALObj, tokenRequest } from '../../../common/authConfig';
import { EmbedResult } from '../../../models/EmbedResult';
import { getAppInsights } from '../../../common/helpers/TelemetryService';
import { CustomError } from "../../../models/Error";
import { upsertErrors } from '../../../store/actions/errorActions';
import { handleApiErrors } from '../../../common/helpers/errorHelpers';
import { LoadingIcon } from "../../../common/helpers/styling/LoadingIcon";
import { TextAlignment } from 'powerbi-models';

//#region Props & State
interface ITopicStoreProps {
    subTopics: Array<SubTopic>;
    topics: Array<Topic>;
    loadedSearchData: Array<SearchDataType>;
    searchOptions: Array<SearchOptionGroup>;
    siteError: CustomError;
}

interface ITopicStoreDispatchProps {
    upsertErrors: (error: CustomError) => void;
}

interface ITopicOwnProps {}

type ITopicProps = ITopicStoreProps & ITopicStoreDispatchProps & ITopicOwnProps;

interface ITopicState {
    relevantSubTopics: Array<SubTopic>;
    loadedSubTopicContent: Array<JSX.Element>;
    urlTopicLocation: string;
    activeSubTopicLink: string;  
    report: EmbedResult;
    majorError: boolean;
    reportLoaded: boolean;
    showSpinner: boolean;
}
//#endregion

//#region Styled Components
const TopicText = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.greys.medium};
  .inner-html{
      & > div > img{display:none;}
  } 
`;
const SubTopicContainer = styled.div`
  h1,h2,h3,h4,h5{
    font-family:${({ theme: { fonts } }) => fonts.title}, 'serif';font-weight: 300;
  }
`;
const SubTopicItem = styled.div`
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.greys.medium};
    margin-bottom: ${({ theme: { padding } }) => padding.large};  
`;
const ReturnLink = styled(Link)`
    display: flex;
  
    padding:1em;
    background:${({ theme: { colors } }) => colors.white};
    position: absolute;
    right: 0;
    top: 50px;z-index: 1;
    color:${({ theme: { colors } }) => colors.greys.medium};
    
    @media ${({ theme: { deviceSizes } }) => deviceSizes.handheldOnly} { 
        display: none;
    }  
`;
const SideLinkTo = styled(Link)`
    background: transparent; text-align:left;
    border: none;
    padding: 0 10px;  font-size: 16px;cursor:pointer;
    color: #6c717d;text-decoration: none;
    .add-icon{display:inline-block;}
    .visible-icon{display:none;}
    
`;

let spinner = document.createElement("div");
spinner.innerHTML = '<div style="text-align: center; height: 100%;"><svg version="1.1" id="Layer_1" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"><rect x="0" y="10" width="4" height="10" fill="#00aeef" opacity="0.2"><animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="8" y="10" width="4" height="10" fill="#00aeef" opacity="0.2"><animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite"></animate></rect><rect x="16" y="10" width="4" height="10" fill="#00aeef" opacity="0.2"><animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate><animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite"></animate></rect></svg></div>';
//#endregion
 
class TopicPage extends React.Component<RouteComponentProps<any> & ITopicProps, ITopicState> {
    private powerbi: pbi.service.Service;
    hasMounted: boolean = false;

    constructor(props: any) {
        super(props);
        this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        // Set Default States
        this.state = {          
            relevantSubTopics: [],
            loadedSubTopicContent: [],
            urlTopicLocation: ``,
            activeSubTopicLink: ``,
            report: new EmbedResult(),
            majorError: false,
            reportLoaded: false,
            showSpinner: true
        };

        getAppInsights().trackPageView();
    }

    //#region LifeCycle Methods
    componentDidMount() {

        this.hasMounted = true; 
        window.addEventListener(`resize`, this.resizeReports);

        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
        // Run on intial mount from a different url redux / component, we check if we have the correct URL Structure
        if (queryStringResult && queryStringResult.title && !this.state.relevantSubTopics.length) {
            let initialSubTopicKey = queryStringResult.subtopic ? queryStringResult.subtopic.toString() : undefined;
            this.loadChildSideNavSubTopics(queryStringResult.title.toString(), initialSubTopicKey);
        }
        // User will be scrolled to the top of the page if arriving here from a different component, only on mobile
        if(window.innerWidth < 768){
            window.scrollTo(0,0);
        }
    } 

    componentDidUpdate(prevProps: ITopicProps, prevState: ITopicState) {

        // If the relevantSubTopics haven't been established yet & we have enough information to do so, set them
        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
        let currentTopic: Topic | undefined;
     
        if (queryStringResult && queryStringResult.title) {
            currentTopic = this.getCurrentTopic(queryStringResult.title.toString());
            let pageViewed:string = queryStringResult.title.toString() + (queryStringResult.subtopic ? (` ` + queryStringResult.subtopic.toString()) : ``);

            // Get subtopics on intial mount
            if (!this.state.relevantSubTopics.length && currentTopic && Topic.hasSubTopics(currentTopic)) {
                let initialSubTopicKey = queryStringResult.subtopic ? queryStringResult.subtopic.toString() : undefined;
                this.loadChildSideNavSubTopics(queryStringResult.title.toString(), initialSubTopicKey);
            } 

            // No subtopics on intial mount
            if (queryStringResult.title !== prevState.urlTopicLocation && prevState.urlTopicLocation !== ``) {
                let initialSubTopicKey = queryStringResult.subtopic ? queryStringResult.subtopic.toString() : undefined;
                this.loadChildSideNavSubTopics(queryStringResult.title.toString(), initialSubTopicKey, true);
            }

            if (this.subTopicInNeedOfReport()) {
                let newSubTopic = this.subTopicInNeedOfReport();
                if (newSubTopic) {
                    let reportElement: HTMLElement | null = document.querySelector(`#${convertFileNameToKey(newSubTopic.blob.name)} .powerBiReport`);
                    let parentElement = reportElement?.parentNode;

                    parentElement?.insertBefore(spinner, reportElement);

                    if (!this.state.reportLoaded) {
                        this.loadPowerBIReport(newSubTopic);
                        this.setState({ reportLoaded: true });
                    }
                }
            }
                         
            // If a relevant subtopic has new meta/html content available from props, update state accordingly
            if (this.props.subTopics.some(st => {
                return ((st.htmlContent && prevProps.subTopics.some(pst => ((pst.blob.name === st.blob.name) && (pst.htmlContent === undefined)))) ||
                        (st.metadata && prevProps.subTopics.some(pst => ((pst.blob.name === st.blob.name) && (pst.metadata === undefined)))));
            })) {  
                let initialSubTopicKey = queryStringResult.subtopic ? queryStringResult.subtopic.toString() : undefined;
                this.loadChildSideNavSubTopics(queryStringResult.title.toString(), initialSubTopicKey);
            }
        }     

        // Handle errors
        if ((prevProps.siteError.errorTitle !== this.props.siteError.errorTitle) && (this.props.siteError.errorTitle !== ``)){
            if (this.props.siteError.showErrorPage) {
                this.setState({ majorError: true });
            }
        }

    }

    componentWillUnmount() {
        this.hasMounted = false;
        clearInterval();
        clearTimeout();
    }
    //#endregion

    render () {
        if (this.state.majorError) {
            return <Redirect to='/Error'/>;
        }
        let sortedTopics:Topic[] = sortArrayElementsByPriority(this.props.topics).filter(t => t.hasAccess);
        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);      
        if (queryStringResult && queryStringResult.title) {
            let topic = sortedTopics.find(t => convertFileNameToKey(t.blob.name) === queryStringResult.title && t.hasAccess);
            let otherTopics = sortedTopics.filter(t => convertFileNameToKey(t.blob.name) !== queryStringResult.title);
            return topic ? (
                <>
                <Row style={{alignContent:`start`, flexGrow: 3 }} grow filled>
                    <Col xs={12} md={3} lg={2} style={{position:`relative`}} hideOnPrint>
                        <ReturnLink to="/">
                            <MdArrowBack size={24}/>
                        </ReturnLink>
                        <SideNavContainer subTopicName={this.state.activeSubTopicLink}>
                            <SideNav>
                                {sortedTopics.map((newtopic, index) => {

                                    if(convertFileNameToKey(newtopic.blob.name) === queryStringResult.title ){
                                        return (
                                            <Sticky key={index}>
                                                <SideNavItem key={-1} topicTitle>
                                                    <SideNavLink >
                                                        {getTitleText(newtopic)}
                                                    </SideNavLink>
                                                </SideNavItem>
                                                {this.state.relevantSubTopics.map((subTopic, subTopicindex) => {
                                                    const isVisible:boolean = this.state.activeSubTopicLink === subTopic.blob.name;
                                                    return (
                                                            <SideNavItem 
                                                                className={`subTopicLi ${isVisible ? ` isVisible` : ``} `}
                                                                key={subTopicindex}>
                                                                <SideNavLink className={this.state.activeSubTopicLink === subTopic.blob.name ? ` isVisible` : ``} onClick={() => this.addSubTopicContentToPage(subTopic)} >
                                                                    {getTitleText(subTopic)}
                                                                    <AddBoxIcon className='add-icon'/>
                                                                    <VisibleIcon  className='visible-icon'/>     
                                                                </SideNavLink>

                                                            </SideNavItem>
                                                        );
                                                    })
                                                }
                                            </Sticky>
                                        );
                                    }else{
                                        return (
                                            <SideNavLink key={index} otherTitle className="otherTopicButton">
                                                <SideLinkTo to={`/Topic?title=${getTitleUrl(newtopic)}`}>
                                                    {getTitleText(newtopic)}
                                                </SideLinkTo>
                                            </SideNavLink>
                                        );
                                    }

                                })}    
                            </SideNav>
                        </SideNavContainer>
                    </Col>
                    <Col pr={12} xs={12} md={9} lg={8} filled grow>
                        <Padding large>
                            <H5 style={{marginTop: 0 }}>
                                <SubLogo />
                            </H5>
                            <H1>{getTitleText(topic)}</H1>

                            {((topic.tagKeys !== undefined) && (topic.tagKeys.length > 0)) && 
                                <TagContainer className='tags-container' small>
                                    {topic.tagKeys.map((tag, index) => {
                                        return (
                                                <TagItem key={index}>
                                                    <TagLink to={`/SearchResults?tag=${tag}`}>
                                                        {tag}   
                                                    </TagLink>
                                                </TagItem>
                                            );
                                        })
                                    }
                                </TagContainer>
                            }

                            <TopicText id={`topic-html`}>
                                <div className='inner-html' dangerouslySetInnerHTML={{__html: topic.htmlContent}}></div>
                            </TopicText>
                            <SubTopicContainer id={`subtopic-html`} >
                                {this.state.loadedSubTopicContent}
                            </SubTopicContainer>
                        </Padding>

                    </Col>
                    <Col xs={12} md={2} style={{paddingTop: `100px` }} hideMobile hideTablet hideOnPrint>

                            {((topic) && (topic.updatedTimestamp !== ``)) && (
                                <H4Time>
                                    Last Updated On:<br/>
                                    {convertToDate(topic.updatedTimestamp)}
                                </H4Time>
                            )}
                        
                        <InputText 
                            options={this.props.searchOptions}  
                            placeholder='Search Alas Edge'
                            isClearable 
                            onChange={(value:any) => this.onSearchTextFieldChange(value)}
                            isDisabled={!this.props.searchOptions.length}  
                        /> 
                        {this.getRelatedTopics(topic, otherTopics)}
                    </Col>
                </Row>

                </>
            ) : <span />;
        } 
        return <span />;        
    }

    //#region Initialize
    private getCurrentTopic = (topicKey: string): Topic | undefined => {
        return this.props.topics.find(t => convertFileNameToKey(t.blob.name) === topicKey);
    }
    private loadChildSideNavSubTopics = (topicKey: string, initialSubTopicKey?: string, clearSubtopics?: boolean): void => {
        let topic = this.getCurrentTopic(topicKey);
        
        if (topic && Topic.hasSubTopics(topic)) {
   
            let relevantSubTopics: Array<SubTopic> = clearSubtopics ? [] : [...this.state.relevantSubTopics];
            let loadedSubTopicContent: Array<JSX.Element> = clearSubtopics ? [] :[...this.state.loadedSubTopicContent];
            topic.subTopicKeys.forEach(stk => {
                let subTopicIndex = this.props.subTopics.findIndex(st => convertFileNameToKey(st.blob.name) === stk); 
                let relevantSubTopicIndex = relevantSubTopics.findIndex(rst => convertFileNameToKey(rst.blob.name) === stk);
                let relevantSubTopic = {...this.props.subTopics[subTopicIndex]};
                if ((relevantSubTopicIndex > -1) && (subTopicIndex > -1)) {
                    relevantSubTopics.splice(relevantSubTopicIndex, 1, relevantSubTopic);
                } else if (subTopicIndex > -1) {
                    relevantSubTopics.push(relevantSubTopic);
                }
            });
            
            if (initialSubTopicKey) {
                let initialSubTopicIndex: number = relevantSubTopics.findIndex(rst => convertFileNameToKey(rst.blob.name) === initialSubTopicKey);
                if (initialSubTopicIndex > -1) {
                    let initialSubTopic: SubTopic = {...relevantSubTopics[initialSubTopicIndex]};
                    this.addSubTopicContentToPage(initialSubTopic, relevantSubTopics, topicKey);
                }
            } else {           
                this.setState({
                    relevantSubTopics,
                    loadedSubTopicContent,
                    urlTopicLocation: topicKey,
                    activeSubTopicLink: ``
                });
            }
    
        } else if (topic && !Topic.hasSubTopics(topic)){ 
            let relevantSubTopics: Array<SubTopic> = clearSubtopics ? [] : [...this.state.relevantSubTopics];
            let loadedSubTopicContent: Array<JSX.Element> = clearSubtopics ? [] :[...this.state.loadedSubTopicContent];        
            this.setState({
                relevantSubTopics, 
                loadedSubTopicContent,
                urlTopicLocation: topicKey,
                activeSubTopicLink: ``
            });
        }
        
    } 
    //#endregion 

    //#region Build Page Content
    private addSubTopicContentToPage = (subTopic: SubTopic, updatedRelevantSubTopics?: Array<SubTopic>, newTopic?:string): void => {
        let originalRelevantSubTopics: Array<SubTopic> = updatedRelevantSubTopics ? updatedRelevantSubTopics : [...this.state.relevantSubTopics];
        let subTopicAlreadyAdded = originalRelevantSubTopics.some(rst => ((rst.blob.name === subTopic.blob.name) && (rst.visible === true)));
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set(`subtopic`, convertFileNameToKey(subTopic.blob.name));
        this.props.history.push(window.location.pathname + `?` + currentUrlParams.toString());
        
        let subTopicElementsToHide = document.querySelectorAll(`.sub-topic`);

        if (subTopicElementsToHide.length) {
            let subTopics: Array<HTMLElement> = [];
            subTopicElementsToHide.forEach(element => {
                subTopics.push(element as HTMLElement );
            });            
            subTopics.forEach(st => st.style.display = `none`);
        }        
        
        if (!subTopicAlreadyAdded && subTopic.metadata && subTopic.htmlContent) {
            this.setState({ reportLoaded: false })
            // Figure out where the SubTopic's content should be added within the current content array
            let currentlyVisibleSubTopics = originalRelevantSubTopics.filter(st => st.visible === true);
            let finalIndex = originalRelevantSubTopics.findIndex(st => st.blob.name === subTopic.blob.name); 

            if (finalIndex > -1) { // relevant subTopic was found
                let targetIndex = 0; // Instantiate the target index (where we'll be adding the subTopic) as 0 (the top of the page)            
                if (finalIndex > 0) { // If the final position of the subTopic is NOT 0 (the top)...
                    let finalPredecessors = originalRelevantSubTopics.slice(0, finalIndex); // Find all predecessor subTopics that have already been made visible
                    let visiblePredecessorIndex = -1; // Instantiate a variable to keep track of the position of the new subtopic's closest already-visible predecessor
                    finalPredecessors.forEach((fp, i) => {
                        let predecessorIndex = currentlyVisibleSubTopics.findIndex(vst => vst.blob.name === fp.blob.name); // get the current index of the visible predecessor
                        if (predecessorIndex > -1) { // if the predecessor is already visible...
                            visiblePredecessorIndex = predecessorIndex; // update the visiblePredecessorIndex variable with the new index
                        }
                    });
                    targetIndex = visiblePredecessorIndex + 1; // update the target index to be 1 after the closest visible predecessor                
                }

                // Update the subTopic's visible status
                let updatedSubTopic = {...subTopic};
                let relevantSubTopics = [...originalRelevantSubTopics];

                updatedSubTopic.visible = true;
                relevantSubTopics.splice(finalIndex, 1, updatedSubTopic);

                // Create the JSX content that will be displayed for the SubTopic
                let subTopicContent: JSX.Element = this.getSubTopicContent(updatedSubTopic, finalIndex);

                let loadedSubTopicContent: Array<JSX.Element> =  [...this.state.loadedSubTopicContent];
                loadedSubTopicContent.splice(targetIndex, 0, subTopicContent);
 
                if(newTopic){
                    this.setState({ 
                        relevantSubTopics, loadedSubTopicContent,
                        urlTopicLocation: newTopic,
                        activeSubTopicLink: subTopic.blob.name
                    });
                }else{
                     
                    this.setState({ 
                        relevantSubTopics, loadedSubTopicContent,
                        activeSubTopicLink: subTopic.blob.name
                    }, () => {
                        let queryStringResult: ParsedQuery<string> = queryString.parse(this.props.location.search);
                        if(queryStringResult.title){
                            let pageViewed:string = queryStringResult.title.toString() + (queryStringResult.subtopic ? (`_` + queryStringResult.subtopic.toString()) : ``);
                            //appInsights.trackPageView({ 
                            //    name: `AlasEdge: `+ pageViewed, 
                            //    refUri:(this.props.location.pathname + this.props.location.search), 
                            //    properties: { 'User Email' : myMSALObj.getAccount().idToken.email, 'User Roles' : myMSALObj.getAccount().idToken.userRoles } 
                            //});              
                        }
                    });

                }
                 
            } else {
                this.setState({                
                    relevantSubTopics: originalRelevantSubTopics
                });
            }
            // Do nothing - the subTopic passed in was not a valid member of the relevant subTopic list for this topic page
        } else {
            let subTopicToShow = document.querySelector(`#${convertFileNameToKey(subTopic.blob.name)}.sub-topic`);
            let convertedSubTopicToShow = subTopicToShow as HTMLElement;
     
            if (convertedSubTopicToShow instanceof HTMLElement) {
                convertedSubTopicToShow.style.display = `block`;
            }
            this.setState({                
                relevantSubTopics: originalRelevantSubTopics,
                activeSubTopicLink: subTopic.blob.name
            });
        }       
        // TODO: Scroll to the pre-existing sub-topic
    }

    //#endregion 
 
    private getRelatedTopics = (topic: Topic, otherTopics: Topic[] | undefined, ): JSX.Element => {

        if(otherTopics && otherTopics.length){
            // Find if any related topics, compare with CurrentTopicTags Array
            let relatedTopics: Topic[] = otherTopics.filter((relatedTopic) => {
                return relatedTopic.tagKeys.some(item => topic.tagKeys.indexOf(item) !== -1);
            });
 
            // If Related Topics is found return elements
            if(relatedTopics && relatedTopics.length > 0 ){
                let relatedTopicsArray:JSX.Element[] = relatedTopics.map((relatedTopic,index) => {
                    return (
                        <FlexListItem key={index}>
                            {this.getTopicImage(relatedTopic.thumbnail, relatedTopic)}
                            <H6><SideLinkTo to={`/Topic?title=${getTitleUrl(relatedTopic)}`}>{getTitleText(relatedTopic)}</SideLinkTo></H6>
                        </FlexListItem>
                    );
                }); 
                // Return Related Topics Elements
                return <><H3 style={{textAlign: `center` }}>Related Topic(s)</H3><FlexList maxWidth={`200px`}>{randomizeArrayElements(relatedTopicsArray).slice(0,2)}</FlexList></>;

            // Else return Nothing    
            } else { return <></>; }; 

        // Else return Nothing    
        } else { return <></>; }; 
    
    }

     // Return Related Topic Main Image  
     private getTopicImage = (imagePath: string | undefined, relatedTopic: Topic ): JSX.Element => {   
        if(!imagePath){     
            return <></>;
        } else {
            return (
                <SideLinkTo to={`/Topic?title=${getTitleUrl(relatedTopic)}`}>
                    <img src={imagePath} alt={getTitleText(relatedTopic)} style={{width:`100%`}}/>
                </SideLinkTo>
            );
        }

    }

    private getSubTopicContent = (subTopic: SubTopic, index: number): JSX.Element => {
        return (
            <SubTopicItem id={convertFileNameToKey(subTopic.blob.name)} key={Math.random()} className={`sub-topic`} >
                <h2 id={`subTopic-title`}>{getTitleText(subTopic)}</h2>    
                <div dangerouslySetInnerHTML={{ __html: subTopic.htmlContent }}></div>
            </SubTopicItem>
        );
    }

    private subTopicInNeedOfReport = (): SubTopic | undefined => {
        return this.state.relevantSubTopics.find(rst => {
            let reportPlaceholder = document.querySelector(`#${convertFileNameToKey(rst.blob.name)} .powerBiReport`);
            let reportHasLoaded = reportPlaceholder ? reportPlaceholder.innerHTML.trim().length > 0 : false;
            return (rst.visible && reportPlaceholder !== null && !reportHasLoaded);
        });
    }

    private loadPowerBIReport = (subTopic: SubTopic): void => {
        let elementToUpdate: HTMLElement | null = document.querySelector(`#${convertFileNameToKey(subTopic.blob.name)} .powerBiReport`);
        if (elementToUpdate && (elementToUpdate.innerHTML.trim() === ``)) {

            let reportId: string | undefined = undefined;
            if (elementToUpdate.hasAttribute(`data-reportId`)) {
                let dynamicPageName = elementToUpdate.getAttribute(`data-reportId`);
                reportId = dynamicPageName ? dynamicPageName : undefined;
            }

            // Set Report Config variables based on data pulled from existing HTML
            let reportPageName: string | undefined = undefined;
            if (elementToUpdate.hasAttribute(`data-reportpage`)) {
                let dynamicPageName = elementToUpdate.getAttribute(`data-reportpage`);
                reportPageName = dynamicPageName ? dynamicPageName : undefined;
            } 
            if (reportId === undefined || reportId.length < 35) {
                return;
            }

            myMSALObj.acquireTokenSilent(tokenRequest).then(resp => {
                getReport(reportId, resp.accessToken).then(response => {
                    
                    let customLayout: pbi.models.ICustomLayout = { displayOption: pbi.models.DisplayOption.ActualSize };
                    let isMobile = window.innerWidth < 640;
                    let settings: pbi.IEmbedSettings = {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: false,
                        customLayout: customLayout,
                        layoutType: isMobile ? pbi.models.LayoutType.MobilePortrait : pbi.models.LayoutType.Master
                    };                    
                    let config: IEmbedConfiguration = {
                        id: response.data.id,
                        embedUrl: response.data.embedUrl,
                        accessToken: response.data.embedToken.token,
                        tokenType: pbi.models.TokenType.Embed,
                        type: `report`,
                        pageName: reportPageName,
                        settings
                    };                   
                    this.setState({ report: response.data });
                    if ((elementToUpdate instanceof HTMLElement) && !elementToUpdate.hasChildNodes()) {
                        let reportContainer: HTMLElement = document.createElement(`div`); 
                        reportContainer.setAttribute(`class`, `power-bi-container`);
                        let containerWidth = elementToUpdate.offsetWidth; // Get the width of the container
                        reportContainer.style.height = (containerWidth * (9/16)) + `px`; // Force a 16:9 aspect ratio
                        this.powerbi.embed(reportContainer, config);
                        elementToUpdate.previousSibling?.remove();
                        elementToUpdate.append(reportContainer);

                        if (subTopic.metadata && subTopic.metadata.powerBIInstructions) {
                            let reportInstructions: HTMLElement = document.createElement(`p`);
                            reportInstructions.innerHTML = subTopic.metadata.powerBIInstructions;
                            elementToUpdate.append(reportInstructions);
                        }
                        this.updateToken(reportId);
                    }
                }).catch((error) => { 
                    !this.props.siteError.statusCode && (
                        this.props.upsertErrors(handleApiErrors(error, `Error getting Report:`))
                    );  
                });

            }).catch((error) => {
                !this.props.siteError.statusCode && (
                    this.props.upsertErrors(handleApiErrors(error, `Error getting Token:`))
                );  
            });
        }
    }

    private updateToken(reportId:string|undefined) {
        clearInterval();//Clear previous internal
        clearTimeout();
        var currentTime = Date.now();
        var expirationTime = Date.parse(this.state.report.embedToken.expiration);
        var timeout = expirationTime - currentTime;
        var refreshInterval = 10 * 60 * 1000; //10 minutes internal to renew B2C token
        if (timeout > 0) {
            setInterval(() => {
                myMSALObj.acquireTokenSilent(tokenRequest)
                    .then((tokenResponse) => {
                        currentTime = Date.now();
                        timeout = expirationTime - currentTime;
                        if (timeout <= refreshInterval) {
                            setTimeout(() => {
                                getReport(reportId, tokenResponse.accessToken)
                                    .then((apiResp: any) => {
                                        //Get htmlelement by class name and set new token is expired
                                        const reportContainer = document.getElementsByClassName(`power-bi-container`)[0] as HTMLElement;
                                        var reportScript = this.powerbi.get(reportContainer);
                                        reportScript.setAccessToken(apiResp.embedToken.token);
                                        //Set new expiration time
                                        expirationTime = Date.parse(apiResp.embedToken.expiration);
                                    }).catch((error) => {
                                        !this.props.siteError.statusCode && (
                                            this.props.upsertErrors(handleApiErrors(error, `Error getting Report:`))
                                        ); 
                                    });
                            }, timeout);
                        }
                    })
                    .catch((error) => {
                        !this.props.siteError.statusCode && (
                            this.props.upsertErrors(handleApiErrors(error, `Error getting Token:`))
                        );  
                    });
            }, refreshInterval);
        }
    }
    //#endregion
 
    //#region Resize
    private resizeReports = (): void => {       
        let reportContainers: Array<Element> | null = Array.from(document.querySelectorAll(`.powerBiReport > div`));
        if (reportContainers && reportContainers.length) {
            reportContainers.forEach(rc => {
                let element = rc as HTMLElement;
                let newWidth = element.offsetWidth;
                element.style.height = (newWidth * (9/16)) + `px`; // Force a 16:9 aspect ratio
            });
        }
        window.removeEventListener(`resize`, this.resizeReports);
        window.addEventListener(`resize`, this.resizeReports);
    }
    //#endregion

    //#region Handle Change of Search Text Field
    private onSearchTextFieldChange = (selection: any): void => {    
        if(!selection){      
            return;
        }
        const selectionLabel: string = selection.label;     
        if (this.props.searchOptions.length) {
            // all we want to do here is pass the phrase through history and let the search page handle what happens from there.
            this.props.history.replace(`/SearchResults?phrase=${selectionLabel}`);           
        }

    }
    //#endregion
}

function mapStateToProps(state: State) {
    return {
        loadedSearchData: state.loadedSearchData,
        subTopics: state.allSubTopics,
        topics: state.allTopics,
        searchOptions: state.allSearchOptions,
        siteError: state.siteError
    };
}
  
function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({
        upsertErrors: upsertErrors
        
    },        
    dispatch);  
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicPage);